import ConfirmPage from "./RegisterConfirmationPage.jsx";
import HomePage from "./HomePage.jsx";
import ProfilePage from "./protected/ProfilePage.jsx";
import LoginPage from "./LoginPage.jsx";
import ResetPasswordRequestPage from "./ResetPasswordRequestPage.jsx";
import ResetPasswordPage from "./ResetPasswordPage.jsx";
import LogoutPage from "./protected/LogoutPage.jsx";
import RegisterPage from "./RegisterPage.jsx";
import CollectionsPage from "./protected/CollectionsPage.jsx";
import ProductPage from "./protected/ProductPage.jsx";
import PriceListPage from "./protected/PriceListPage/PriceListPage.jsx";
import ImporterPage from "./protected/ImporterPage.jsx";
import UsersPage from "./protected/UsersPage/UsersPage.jsx";
import Algolia from "./protected/Algolia.jsx";

export {
  ConfirmPage,
  HomePage,
  ProfilePage,
  LoginPage,
  ResetPasswordRequestPage,
  ResetPasswordPage,
  LogoutPage,
  RegisterPage,
  CollectionsPage,
  ProductPage,
  PriceListPage,
  ImporterPage,
  UsersPage,
  Algolia
};
