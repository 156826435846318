import React from "react";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

export default function NavBar() {

  const {user, isAuth} = useSelector((state) => ({
    user: state.user.user,
    isAuth: state.user.isAuth,
  }));
 
  const pages = [
    {
      "label": "Collections", 
      "target": "/collections",
      "isAdmin": true
    },
    {
      "label": "Create",
      "target": "/products",
      "isAdmin": true
    },
    {
      "label": "Product List",
      "target": "/pricelist",
      "isAdmin": false
    }
    ,
    {
      "label": "Importer",
      "target": "/importer",
      "isAdmin": true
    },
    {
      "label": "Users",
      "target": "/users",
      "isAdmin": true
    }

  ];

  
  const filterAdminPages = () => {
    if ( !user?.isAdmin || !isAuth )    
      return  pages.filter((page) => (page.isAdmin == false));  
    else
      return pages;
  }

  const totalPages = filterAdminPages();

  const settings = [
    {
      label: "Search Engine",
      target: "/search-engine",
    },
    {
      label: "Logout",
      target: "/logout",
    }
  ]

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    window.location.href = event.currentTarget.getAttribute("href");
    setAnchorElUser(null);
  };

  return (


<AppBar position="static">
<Container maxWidth="xl">
  <Toolbar disableGutters>
    <Typography
      variant="h6"
      noWrap
      component="div"
      sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
    >
      WOOG
    </Typography>

    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >        
        { isAuth ? pages.map((page) => (
          <MenuItem key={page.label}   >
            <a href={page.target}>
            <Typography textAlign="center">{page.label}111</Typography>
            </a>
          </MenuItem>
        )) : ''
        }
      </Menu>
    </Box>
    <Typography
      variant="h6"
      noWrap
      component="div"
      sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
    >
      LOGO
    </Typography>
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      
    { isAuth ? totalPages.map((page) => (
        
        <Button
          key={page.label}
          href={page.target}
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          {page.label}
        </Button>
      )): ''}
    </Box>

    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          
          <MenuItem key={setting} href={setting.target} onClick={handleCloseUserMenu}>            
            <Typography textAlign="center">{setting.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  </Toolbar>
</Container>
</AppBar>
  );
}
