import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { attemptGetUser } from "./store/thunks/user";
import "./index.scss"


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

import {
  ConfirmPage,
  HomePage,
  ProfilePage,
  LoginPage,
  ResetPasswordRequestPage,
  ResetPasswordPage,
  LogoutPage,
  RegisterPage,
  CollectionsPage,
  ProductPage,
  PriceListPage,
  ImporterPage,
  UsersPage,
  Algolia
} from "./pages";

import { ProtectedRoute, NavBar, CustomLoader } from "./components";


export default function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {    
    dispatch(attemptGetUser())
      .then((data) => {        
        setLoading(false);
      })
      .catch((err) => {
        console.log('errooor', err)
        setLoading(false);
      });
  }, [dispatch]);



  return loading ? (
    <>      
    <CustomLoader     
    />
    </>
  ) : (
    <React.Fragment>     
        <header>
          <NavBar />
        </header>        
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{height: "100%" }}>
            <Grid item xs={12} md={11} sx={{ margin: '1rem auto', height: "100%" }}>
              <Switch>
                <Route path='/home' exact component={LoginPage} />
                <Route path='/account/confirm/:token' exact component={ConfirmPage} />
                <Route path='/register' exact component={RegisterPage} />
                <Route path='/login' exact component={LoginPage} />
                <Route path='/login/forgot' exact component={ResetPasswordRequestPage} />
                <Route path='/login/reset/:token' component={ResetPasswordPage} />
                <ProtectedRoute path='/collections' exact component={CollectionsPage} />
                <ProtectedRoute path='/products' exact component={ProductPage} />
                <ProtectedRoute path='/products/:paramId' exact component={ProductPage} />
                <ProtectedRoute path='/pricelist' exact component={PriceListPage} />
                <ProtectedRoute path='/importer/' exact component={ImporterPage} />
                <ProtectedRoute path='/logout' exact component={LogoutPage} />
                <ProtectedRoute path='/my-profile' exact component={ProfilePage} />
                <ProtectedRoute path='/users' exact component={UsersPage} />
                <ProtectedRoute path='/search-engine' exact component={Algolia} />
                <Redirect from='/' exact to='/login' />
                <Redirect to='/login' />
              </Switch>           
            </Grid>
          </Grid>                
    </React.Fragment>
  );
}
