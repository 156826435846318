// import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

const IndexSelector = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [indexesList, setindexesList] = useState([]);  
  useEffect(() => {   
    async function getIndexes() {      
        setindexesList(props.websitesData);
        setLoaded(true);      
    }
    getIndexes();
  }, []);

  if (!loaded) {
    return <p>Loading lists...</p>;
  } else {
    return (
      <>
        <select
          className="cmb-index form-select"
          onChange={(e) => {
            props.sendDataToParent(
              e.target.value,
              e.target.selectedOptions[0].getAttribute("data-domain")
            );
          }}
        >
          <option value="0">
             Index Name
          </option>
          {indexesList.map((element, key) => (
            <option
              key={key}
              data-domain={element.domain}
              value={element.indexName}
            >
              {element.site}
            </option>
          ))}
        </select>
      </>
    );
  }
};

export default IndexSelector;
