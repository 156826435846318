import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import httpService from "../../../services/httpService";
import { useInitialData } from "./hooks/useInitialData.js"
import { getCols } from "../../../api/priceList";
import CircularProgress from '@mui/material/CircularProgress';

import Checkbox from "@mui/material/Checkbox";

export default function PriceListPage() {  
  const { rows, loading } = useInitialData({ httpService })
  const columns = getCols(Checkbox);

  if (loading)
    return(
      <>
      <div style={{display: "flex", justifyContent:"center", height: "100%" }}>
        <div style={{ alignSelf:"center", textAlign:"center"}}>
            <div><CircularProgress /></div>
            <p>Loading data, pls wait...</p>
        </div>
      </div>
      </>
    )

  return (
    <div style={{display: "flex", height: "92%"}}>          
      <DataGrid
        rows={rows}
        columns={columns}
        components={{Toolbar: GridToolbar}}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {debounceMs: 500},
          },
        }}
        // getRowClassName={(params) => `bg-${params.row.isSellable}`}
      />
    </div>
  );
}
