import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Error } from "../components";
import {
  attemptRegister,
  attemptResendConfirmation,
  attemptResetRegister,
} from "../store/thunks/auth";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function RegisterPage() {
  const { isAuth } = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");
  const [email, setEmail] = useState("");
  const [registerStep, setRegisterStep] = useState("register"); // Use an enum with TS;

  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().min(5).max(255).email().required("Required"),
    username: Yup.string().min(3).max(50).required("Required"),
    password: Yup.string().min(5).max(255).required("Required"),
  });

  const onSubmit = (values) => {
    dispatch(attemptRegister(values))
      .then(() => {
        setEmail(values.email);
        setRegisterStep("resend");
      })
      .catch((error) => {
        if (error.response) {
          setServerError(error.response.data.message);
        }
      });
  };

  const onResendEmail = () => {
    dispatch(attemptResendConfirmation(email))
      .then(() => {
        setRegisterStep("reset");
      })
      .catch((error) => {
        if (error.response) {
          setServerError(error.response.data.message);
        }
      });
  };

  const onReset = () => {
    dispatch(attemptResetRegister(email)).catch((error) => {
      if (error.response) {
        setServerError(error.response.data.message);
      }
    });
  };

  const formik = Formik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const formitData = formik.props.value

  function renderSwitch() {
    switch (registerStep) {
      case "register":

        return (          
            <form
             style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1.5rem", padding: "1rem" }}  
              autoComplete="off"
              onSubmit={formitData.handleSubmit}
            >
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                placeholder="Email"
                value={formitData.values.email}
                onChange={formitData.handleChange}
                error={formitData.touched.email && Boolean(formitData.errors.email)}
                helperText={formitData.touched.email && formitData.errors.email}
              />

              <TextField
                fullWidth
                id="username"
                name="username"
                label="Username"
                variant="outlined"
                placeholder="Username"
                value={formitData.values.username}
                onChange={formitData.handleChange}
                error={formitData.touched.username && Boolean(formitData.errors.username)}
                helperText={formitData.touched.username && formitData.errors.username}
              />
              
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                placeholder="Password"
                value={formitData.values.password}
                onChange={formitData.handleChange}
                error={formitData.touched.password && Boolean(formitData.errors.password)}
                helperText={formitData.touched.password && formitData.errors.password}
              />

              <FormControl fullWidth >
                <Button color="primary" variant="contained" type="submit" fullWidth disabled={!formitData.dirty || !formitData.isValid}>
                Signup
                </Button>
              </FormControl>
              
              {serverError && <Error>{serverError}</Error>}
            </form>          
        );
      case "resend":
        return (
          <Grid item xs={12} md={4}>
            <p>A verification email has been sent.</p>
            <p>Check you mailbox : {email}.</p>
            <p>
              You have 12 hours to activate your account. It can take up to 15 min to receive our
              email.
            </p>
            <button onClick={onResendEmail}>
              Did not receive the email? Click here to send again.
            </button>
            {serverError && <Error>{serverError}</Error>}
          </Grid>
        );

      case "reset":
        return (
          <Grid item xs={12} md={6}>
            <p>Still not received an email? </p>
            <p>Try to register again. You may have given the wrong email. </p>
            <p>If you want to be able to use the same username, reset the registration :</p>
            <button onClick={onReset}>Click here to reset the registration</button>
            {serverError && <Error>{serverError}</Error>}
          </Grid>
        );
      default:
        break;
    }
  }

  return isAuth ? (
    <Redirect to="/home" />
  ) : (
    <Grid
      container
      style={{display: "flex", alignItems: "center", alignContent: "center"}}
    >
      <Grid item container>
        <Grid item xs={12} md={5} sx={{mx: "auto"}}>
          <Card>
            <CardContent>{renderSwitch()}</CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
