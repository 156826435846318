import React from "react";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

export default function CustomLoader() {

  
  const [loading, setOsetLoadingpen] = React.useState(false);
  const handleClose = () => {
    setOsetLoadingpen(false);
  };
  const handleToggle = () => {
    setOsetLoadingpen(!loading);
  };

  return (
    <div>      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
