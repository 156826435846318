import React from "react";


const FileListDisplay = ({ fileData }) => {

    return(
        <>
        {
            fileData.map((file) => {
                return(<div>📎 <a href={file.path} rel="noreferrer" target="_blank">{file.filename}</a></div>)
            })
        }
        </>
    )
}

export default FileListDisplay;