import httpService from "../services/httpService";

const fetchCollectionsList = async () => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
  const collectionUrl = `${baseUrl}/collection/get`;
  const collectionList = await httpService.get(collectionUrl);
  return collectionList.data;
}

export default fetchCollectionsList;
