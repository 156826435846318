import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";


const RenderConfirmDialog = ({iseDeleteDialogOpened, productData, handleCloseDeleteDialog, handleYesDeleteDialog}) => {    
  // const handleYes = () => {};
  return (
    <Dialog maxWidth="xs" open={iseDeleteDialogOpened}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent dividers>
        {`Pressing 'Yes' will change ${productData}.`}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteDialog}>No</Button>
        <Button variant="contained" color="error"onClick={handleYesDeleteDialog}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenderConfirmDialog; 