import React, {useEffect, useState} from "react";
import TableHeader from "../Algolia/TableHeader";
import TableBody from "../Algolia/TableBody";

const TableHolder = (props) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);  

  useEffect(() => {
    setLoaded(false);
    async function makeRequest() {
      if (props.selectedIndex !== 0) {
        setLoaded(false);

        let algoliaQueryFields = props.headerData
        .filter((item) => {
          if (typeof item.field != "undefined") {             
              return item              
          } else {
            return null;
          }
        })
        .map((item) => item.field);          
      

        await fetch(`https://opensearch.abitare.acsone.eu/${props.selectedIndex}/_search`, {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({    
              _source: algoliaQueryFields,              
              size: 10000,
              aggs: { },
          })
      })
      .then(response => response.json())
      .then(data => {
          const productList = data?.hits?.hits.map((element) =>{
            return element._source            
          })
          setData(productList);
          setLoaded(true);
      })
      .catch(error => {
          console.error('error', error);
      });
        
      }
    }

    if (props.selectedIndex !== "" && props.selectedIndex !== 0) {
      makeRequest();
    }
  }, [props.selectedIndex]);

  if ( props.selectedIndex === 0){
    return <>
      <div class="center-div">  
        <div>No index selected..</div>        
      </div>
    </>;
  }

  if (!loaded)
    return (
      <>
        <div class="center-div">
          <div>Loading data...</div>
          <div class="lds-facebook"></div>
        </div>
      </>
    );

  return (
    <>
      <TableHeader className="table-header" headerData={props.headerData} />

      <TableBody
        className="table-header"
        headerData={props.headerData}
        tableData={data}
        baseURL={props.baseURL}
        selectedTarget={props.selectedTarget}
      />
    </>
  );
  
};

export default TableHolder;
