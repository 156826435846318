import React from "react";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {Typography} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {makeStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const AccordionSummary = styled((props) => {
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: "0.9rem"}} />}
      {...props}
    />
  );
})(({theme}) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CollectionSummary = ({
    element,
    openDynamicDialog,
    openEditDynamicDialog,
  }) => {
    const classes = useStyles();
  
    const handleAttachFileClick = (event) => {
      event.stopPropagation();
      openDynamicDialog(event);
    };
  
    const handleAddFieldClick = (event) => {
      event.stopPropagation();
      openDynamicDialog(event);
    };
  
    const handleEditClick = (event) => {
      event.stopPropagation();
      openEditDynamicDialog(event);
    };
  
    return (
      <AccordionSummary id={element._id} key={element._id}>
        <Typography sx={{width: "35%", flexShrink: 0, alignSelf: "center"}}>
          {element.name.toUpperCase()}
        </Typography>
        <Typography sx={{width: "65%"}} className={classes.root}>
          <Button
            variant="contained"
            className="mx-2"
            onClick={handleAttachFileClick}
            startIcon={<AttachFileIcon />}
            data-type="attachment"
            data-parentid={element._id}
          >
            Attach a file
          </Button>
          <Button
            variant="contained"
            onClick={handleAddFieldClick}
            data-type="collection-link-extra"
            data-parentid={element._id}
          >
            + Add field
          </Button>
          <Button
            variant="outlined"
            onClick={handleEditClick}
            sx={{marginLeft: 1}}
            data-id={element._id}
            data-type="collection-creation"          
          >
            Edit
          </Button>
        </Typography>
      </AccordionSummary>
    );
  };
  

export default CollectionSummary;
