export const initValues = {
    name: "",
    brand: "",
    category: "",
    sku: "",
    barcode: "",
    supplier: "",
    supplierCode: "",
    color: "",
    volume: "",
    weight: "",
    isFollow: false,
    isBOM: false,
    isComponent: false,
    isSellable: false,
    isOutOfCollection: false,
    quantity: 0,
    purchasePrice: "0",
    height: "",
    width: "",
    intragroup: "0",
    b2b_a: "0",
    b2b_b: "0",
    b2b_c: "0",    
    depth: "",
    purchasePriceHistory: [],
    image: "",
    rsp:"0",
    rsp2:"0"
  };
