import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TableTd from "./TableTd";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function DisplaySuggested({ suggested, setValues }) {
	const acceptSuggestPrices = async () => {
		setValues((oldValues) => ({...oldValues,
			['b2b_a']: suggested.b2b_a,
			['b2b_b']: suggested.b2b_b,
			['b2b_c']: suggested.b2b_c,
			['rsp']: suggested.rsp,
			['rsp2']: suggested.rsp2
		}));

	};

	return (
		<>
			<TableTd label="Suggested Prices" />
			<TableTd label={<>€ {suggested.b2b_a}</>} />
			<TableTd label={<>€ {suggested.b2b_b}</>} />
			<TableTd label={<>€ {suggested.b2b_c}</>} />
			<TableTd label={<>€ {suggested.rsp}</>} />
			<TableTd
				label={
					<>
						€ {suggested.rsp2}
						<Button
							onClick={acceptSuggestPrices}
							startIcon={<DoneAllIcon />}
							alt="Accept suggested prices"
						/>
					</>
				}
			/>
		</>
	);
}
