import React, {useContext} from "react";
import {styled} from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {CollectionsContext} from "../context/CollectionsContext.js";
import httpService from "../services/httpService.js";
import TextEditorCreator from "./TextEditorCreator.jsx";
import ImageGallery from "./ImageGallery/ImageGallery.jsx";
import CollectionSummary from "./CollectionSummary/CollectionSummary.jsx";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));



const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CollectionsHolder({setCollectionList}) {
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //GET CONTEXT VARS
  const {
    collectionsList,
    setEditCollection,
    setParentID,
    setDialogOpened,
    openDynamicDialog,
    expanded,
    setExpanded,
    openEditDynamicDialog,
    dialogType,
    setDialogType,
    loadData,
    editChild,
  } = useContext(CollectionsContext);

  const deleteChild = async (e) => {
    // GET URL
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const collectionDeleteUrl = `${baseUrl}/collection/deleteExtraFields`;

    // init slate editor
    const data = {
      parentId: e.target.dataset.parentid,
      childId: e.target.dataset.childid,
    };

    // Post request /api/collection/deleteExtraFields
    httpService
      .post(collectionDeleteUrl, data)
      .then(async (res) => {
        setEditCollection([]);
        setParentID(0);
        setDialogOpened(false);
        setDialogType(null);
        loadData();
        // TODO : Remove it from the table to avoid full refresh
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      {collectionsList.map((element) => {
        return (
          <Accordion
            expanded={expanded === element._id}
            onChange={handleChange(element._id)}
          >
            <CollectionSummary
              element={element}
              openDynamicDialog={openDynamicDialog}
              openEditDynamicDialog={openEditDynamicDialog}
            />
            <AccordionDetails>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{xs: 1, sm: 2, md: 3}}
              >
                <Grid item xs={9}>
                  <TableContainer component={Paper} sx={{width: "100%"}}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Actions</b>
                          </TableCell>
                          <TableCell>
                            <b>Extra Field Name</b>
                          </TableCell>
                          <TableCell align="right">
                            <b>Value</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {element.extraFields.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {border: 0},
                            }}
                          >
                            <TableCell component="th" scope="row" size="small">
                              <Button
                                aria-label="delete"
                                data-parentid={element._id}
                                data-childid={row._id}
                                onClick={deleteChild}
                                style={{
                                  color: "red",
                                  display: "inline",
                                  margin: "auto",
                                  minWidth: "unset",
                                }}
                              >
                                ❌
                              </Button>
                              <Button
                                aria-label="edit"
                                data-parentid={element._id}
                                data-id={row._id}
                                data-name={row.name}
                                data-value={row.value}
                                onClick={editChild}
                                style={{
                                  display: "inline",
                                  margin: "auto",
                                  minWidth: "unset",
                                }}
                                data-type="collection-link-extra"
                              >
                                ✏️
                              </Button>
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>

                            <TableCell align="right">{row.value}%</TableCell>
                          </TableRow>
                        ))}
                        <TableRow
                          key="{{element._id}}-total"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>TOTAL</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.extraFieldsTotal
                              ? element.extraFieldsTotal
                              : "0"}
                            %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={3}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          key="{element._id}-b2b_a"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>B2B Premium</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.pricing.b2b_a ? element.pricing.b2b_a : ""}{" "}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow
                          key="{element._id}-b2b_b"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>B2B Premium Online</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.pricing.b2b_b ? element.pricing.b2b_b : ""}{" "}
                            %
                          </TableCell>
                        </TableRow>

                        <TableRow
                          key="{element._id}-b2b_c"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>B2B Reseller</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.pricing.b2b_c ? element.pricing.b2b_c : ""}{" "}
                            %
                          </TableCell>
                        </TableRow>

                        <TableRow
                          key="{element._id}-rsp"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>RSP</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.pricing.rsp ? element.pricing.rsp : ""} %
                          </TableCell>
                        </TableRow>

                        <TableRow
                          key="{element._id}-rsp2"
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell />
                          <TableCell component="th" scope="row">
                            <b>RSP 2</b>
                          </TableCell>
                          <TableCell align="right">
                            {element.pricing.rsp2 ? element.pricing.rsp2 : ""} %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} xl={element.files.length > 0 ? 6 : 12}>
                  <TextEditorCreator type="collection" element={element} />
                </Grid>
                {element.files.length > 0 && (
                  <Grid item xs={12} xl={6}>
                    <ImageGallery parentId={element._id}  files={element.files} setValues={setCollectionList} type="collection"/>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
