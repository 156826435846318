

export default function parentListCols() {
    return [
        {
            id: 12,
            field: "image",
            headerName: "Image",
            width: 100,
            renderCell: (params) => {
                return (<a href={`/products/${params.row._id}`} target="_blank"><img src={params.row.image} width="75%" /></a>);
            }
        },
        {
            id: 0,
            field: "sku",
            headerName: "SKU",
            width: 100,
            renderCell: (params) => {
                return (<a href={`/products/${params.row._id}`} target="_blank">{params.row.sku}</a>);
            }
        },
        {
            id: 1,
            field: "name",
            headerName: "Name",
            width: 450,
            renderCell: (params) => {
                return (<a href={`/products/${params.row._id}`} target="_blank">{params.row.name}</a>);
            }
        },
        {
            id: 2,
            field: "brand",
            headerName: "Brand",
            width: 100,
          }
        ,
        {
            id:3 ,
            field: "parentQty",
            headerName: "Quantity",
            width: 100,
          }
        ,
        {
            id:3 ,
            field: "cost",
            headerName: "Cost",
            width: 100,
          }
    ];
}
