import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DeleteIcon from '@material-ui/icons/Delete';
import pdfFile from "../../assets/pdf-file.png";
import {removeProductAttachment, removeCollectionAttachment } from '../../api/index'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    height: '300px',
    margin: '15px'
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    zIndex: '10000'
  },
}));

export default function ImageGallery(props) {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);

  
  const handleDelete = async (event, file) => {
    event.stopPropagation();
    if (props.type === "product") {
      // delete file using API or other method
      const newFileList = await removeProductAttachment(props.parentId, file);
      props.setValues((oldValues) => ({...oldValues, files: newFileList.data}));
    } else if (props.type === "collection") {
      const newFileList = await removeCollectionAttachment(
        props.parentId,
        file
      );
      // props.setValues((oldValues) => ({...oldValues, 'files': newFileList.data}));
      props.setValues((oldValues) => {
        const updatedValues = oldValues.map((value) => {
          if (value._id === props.parentId) {
            return {...value, files: newFileList.data};
          } else {
            return value;
          }
        });
        return updatedValues;
      });
    }
  };

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={1}>
        {props.files.map((file) => (          
            <div
              onMouseEnter={() => setSelectedFile(file)}
              onMouseLeave={() => setSelectedFile(null)}
              style={{ position: 'relative', height: "auto !importan" }}
            >
              <a href={file.path} target="_blank" rel="noreferrer">
                {file.format === 'application/pdf' ? (
                  <img
                    src={pdfFile}
                    alt={file.name}
                    width="100px"
                    className="placeholder-file"                    
                  />
                ) : (
                  <img src={file.path} alt={file.filename} width="100%"/>
                )}
              </a>
              {selectedFile?._id === file._id && (
                <div
                  className={classes.deleteButton}
                  onClick={(event) => handleDelete(event, selectedFile._id)}
                >
                  <DeleteIcon />
                </div>
              )}
              <GridListTileBar
                title={file.filename}
                subtitle={<span>{file.format}</span>}
              />
            </div>          
        ))}
      </GridList>
    </div>
  );
}
