import React from "react";
import { Grid } from "@mui/material";

const CollectionExtraFields = ({ collection }) => {
  return (
    <Grid item xs={12} lg={6}>
      {collection && (
        <div key={`collection-extra-fields-${Math.random(200)}`}>
          <div key={`collection-extra-fields-${Math.random(200)}`}>
            {collection.extraFields.map((element) => {
              return (
                <div key={`collection-extra-fields-${element._id}`}>
                  <small>
                    {element.name} : {element.value}%
                  </small>
                </div>
              );
            })}
          </div>
          <div key={`collection-extra-fields-${Math.random(200)}`}>
            <b>
              <small>TOTAL: </small>
              {collection.extraFieldsTotal}%
            </b>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default CollectionExtraFields;
