import Snackbar from '@material-ui/core/Snackbar';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import AppBar from "@mui/material/AppBar";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCollectionList } from "../../api/index";
import CollectionExtraFields from "../../components/CollectionExtrafields/CollectionExtrafields";
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { handleCloseDialog, handleOpenDialog } from "../../components/CustomDialog/CustomDialogHooks";
import Dropzone from "../../components/Dropzone/Dropzone.jsx";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import LinkingProduct from "../../components/LinkingProduct.jsx";
import Notifications from "../../components/Notification.jsx";
import PricingDetails from "../../components/PricingDetails.jsx";
import ProductComponents from "../../components/ProductComponentsList/ProductComponents.jsx";
import ProductForm from "../../components/ProductForm.jsx";
import TextEditorCreator from "../../components/TextEditorCreator.jsx";
import { UploadButton } from "../../components/UploadButton/UploadButton";
import utils from "../../components/utils.js";
import { exporterContext } from "../../context/Context.js";
import { handleClearError, handleUpload } from "../../helpers/ProductPage/AttachmentHandler";
import { initRowsData } from "../../helpers/ProductPage/initRowsData";
import { initValues } from "../../helpers/ProductPage/initValues";
import uploadService from "../../services/uploadService";
import ChildrenComponent from '../../components/ChildrenComponents/ChildrenComponents';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductPage() {
  // Redux state
  const {user} = useSelector((state) => state.user);

  // React state
  const [collection, setCollection] = useState("");
  const [newComponentOpen, setNewComponentOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [isBOM, setIsBOM] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [collectionsList, setCollectionsList] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [values, setValues] = useState(initValues);
  const [productId, setProductId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [images, setImages] = useState([]);
  const [pdfs, setPdfs] = useState([]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  

  
  const handleUploadCallback = (response) => {
    setUploadLoading(false);
    setOpenDialog(false);
    setSnackbarMessage('Files successfully uploaded');
    setSnackbarOpen(true);
    setValues((oldValues) => ({ ...oldValues, files: response }));
  }
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };

  // Define base URL
  const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

  // Get URL parameters
  const {paramId} = useParams();

  const notification = (message) => {
    setNotificationMessage(message);
    setOpenNotification(true);
  };

  const handleNewProductClose = () => {
    setNewComponentOpen(false);
  };
  const fetchData = async () => {
    if (productId === "" && paramId) {
      setProductId(paramId);
      setIsUpdate(true);
    }
    if (!isBOM) {
      const collectionList = await getCollectionList();
      setCollectionsList(collectionList);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Store values while form is beign filled up
  const textEditorInitData = {
    _id: values._id || "",
    commnents: values.commnents || "",
  };

  const set = (name) => {
    return ({target: {value}}) => {
      const newValue =
        value == "true" || value == "false" ? Boolean(value) : value;
      // const newValueReplaced = (newValue.indexOf(',') != -1 && isNumeric(newValue)) ? newValue.replace(',', '.') : newValue;

      setValues((oldValues) => ({...oldValues, [name]: newValue}));
    };
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  const collectionChange = (e, newValue) => {
    setCollection(newValue);
    validatemissingPrice(newValue);
  };

  const validatemissingPrice = (newValue) => {
    if (values.isSellable && values.isBOM == false && newValue) {
      if (values.b2b_a == 0 || values.b2b_b == 0 || values.b2b_c == 0) {
        const intragroupCalc = utils.customRound(
          (values.purchasePrice * newValue.extraFieldsTotal) / 100,
          2
        );
        values.intragroup = intragroupCalc;
        values.b2b_a = utils.customRound(
          intragroupCalc * (newValue.pricing.b2b_a / 100)
        );
        values.b2b_b = utils.customRound(
          intragroupCalc * (newValue.pricing.b2b_b / 100)
        );
        values.b2b_c = utils.customRound(
          intragroupCalc * (newValue.pricing.b2b_c / 100)
        );
        values.rsp = utils.customRound(
          intragroupCalc * (newValue.pricing.rsp / 100)
        );
        values.rsp2 = utils.customRound(
          intragroupCalc * (newValue.pricing.rsp2 / 100)
        );
      }
    }
  };

  return (
    <exporterContext.Provider
      value={{
        collection,
        set,
        initRowsData,
        setCollection,
        rows,
        setRows,
        isBOM,
        setIsBOM,
        setNewComponentOpen,
        productId,
        setProductId,
        values,
        setValues,
        initValues,
        baseUrl,
        isUpdate,
        notificationMessage,
        openNotification,
        setOpenNotification,
        notification,
        setImages,
        setPdfs,
        pdfs
      }}
    >
      <Grid container>
        <Grid item container className="border-bottom">
          <Grid item xs={12} lg={8} className="mx-auto bg-white p-lg-4">
            <h4 className="mt- mb-3">Product creation</h4>
            <small>{productId}</small>
          </Grid>
          <Grid item xs={12} lg={4} className="bg-white p-lg-4 text-end">
            <Button
              variant="contained"
              className="mx-2"
              onClick={() => {
                handleOpenDialog(setOpenDialog);
              }}
              startIcon={<AttachFileIcon />}
              // data-type="attachment"
              // data-parentid={element._id}
            >
              Attach a file
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={4} mt={2}>
            <Autocomplete
              id="filter-demo"
              options={collectionsList}
              getOptionLabel={(option) => option.name}
              filterOptions={filterOptions}
              value={collection ? collection : null}
              sx={{width: 300}}
              renderInput={(params) => {
                return <TextField {...params} label="Collection" />;
              }}
              onChange={collectionChange}
            />
          </Grid>
          <CollectionExtraFields collection={collection} />
          <Grid item xs={12} lg={2}>
            {values.image !== "" && <img src={values.image} width="75%" />}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <div className="bom-component-holder">
              <h4 className="mt-5 mb-3">Basic Information:</h4>
              <hr></hr>
              <ProductForm initProduct={productId} user={user} />
            </div>
          </Grid>
        </Grid>
        {isBOM === true && productId !== "" && (
          <ProductComponents user={user} />
        )}

        
        {user.isAdmin && (
          <Grid container>
            <Grid item xs={12} xl={images?.length > 0 ? 6 : 12}>
              <TextEditorCreator type="product" element={values} />
            </Grid>
            {images?.length > 0 && (
              <Grid contaitem xs={12} xl={6}>
                <ImageGallery
                  parentId={values._id}
                  files={images}
                  setValues={setValues}
                  type="product"
                />
              </Grid>
            )}
          </Grid>
        )}
        <ChildrenComponent
          productId={productId}
        />
        {collection !== "" && collection && <PricingDetails user={user} />}
       
        <Grid item container>
          <Dialog
            open={newComponentOpen}
            onClose={handleNewProductClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{position: "relative"}}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleNewProductClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                  Add component
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid item xs={12} sx={{margin: 3}}>
              <LinkingProduct user={user} />
            </Grid>
          </Dialog>
        </Grid>
      </Grid>
      <Notifications />
      <CustomDialog
        open={openDialog}
        handleClose={() => handleCloseDialog(setOpenDialog)}
      >
        <div>
          <h2>Attach files to Product</h2>
          <small>Allowed images and pdf files.</small>
        </div>
        <Dropzone
          fileList={fileList}
          setFileList={setFileList}
          setErrorMessage={setErrorMessage}          
        />
        {errorMessage && (
          <div
            className="error-message text-danger"
            onClick={() => handleClearError(setErrorMessage)}
          >
            {errorMessage}
          </div>
        )}
        <Grid container justifyContent="flex-end" sx={{mt: 2}}>
          <UploadButton
            onClick={async () => {
              setUploadLoading(true);
              await handleUpload(
                values._id,
                fileList,
                setErrorMessage,
                uploadService,
                handleUploadCallback
              );
            }}
            loading={uploadLoading}
          />
        </Grid>
      </CustomDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </exporterContext.Provider>
  );
}