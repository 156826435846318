import React, {useState, useContext, useEffect } from "react";
import {useSelector} from "react-redux";

import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';


import DynamicDialog from "../../components/DynamicDialog/DynamicDialog";
import httpService from "../../services/httpService.js";
import CollectionsHolder from "../../components/CollectionsHolder.jsx";

import {CollectionsContext} from "../../context/CollectionsContext.js";


export default function Collections() {
  // const { username } = useSelector((state) => state.user.user);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [parentID, setParentID] = useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const [editCollection, setEditCollection] = React.useState([]);
  const [editAttributes, setEditAttributes] = React.useState([]);

  const [collectionsList, setCollectionList] =
    React.useState([]);

  useEffect( async ()=> {   
    await loadData();
  }, [dialogType]);

  const loadData = async () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const collectionUrl = `${baseUrl}/collection/get`;
    const collectionListData = await httpService.get(collectionUrl);        
    setCollectionList(collectionListData.data);
  }

  const openDynamicDialog = (e) => {    
    e.target.dataset.parentid ? setParentID(e.target.dataset.parentid) : setParentID(0);
    setDialogType(e.target.dataset.type);
    setDialogOpened(true);
  }

  const openEditDynamicDialog = (e) => {   
    
    const collectionFiltered = collectionsList.filter((collection)=> (e.target.dataset.id == collection._id))[0];    
    let response = [];    
    if( collectionFiltered){
        response['id'] = collectionFiltered._id;
        response['name'] = collectionFiltered.name;
        response['pricing.b2b_a'] = collectionFiltered.pricing.b2b_a;
        response['pricing.b2b_b'] = collectionFiltered.pricing.b2b_b;
        response['pricing.b2b_c'] = collectionFiltered.pricing.b2b_c;
        response['pricing.rsp'] = collectionFiltered.pricing.rsp;    
        response['pricing.rsp2'] = collectionFiltered.pricing.rsp2;                
    }
    setEditCollection(response);    
    setEditAttributes([]);    
    setDialogType(`${e.target.dataset.type}`);
    setDialogOpened(true);
  }

  const editChild = (e) => {
    console.log('e', e.target);

    e.target.dataset.parentid ? setParentID(e.target.dataset.parentid) : setParentID(0);

    const collectionId = e.target.dataset.parentid;
    const attributeName = e.target.dataset.name;
    const attributeValue = e.target.dataset.value;
    const attributeId = e.target.dataset.id;

    let response = [];
    response['collectionId'] = collectionId ;
    response['name'] = attributeName;
    response['attributeValue']  = attributeValue ;
    response['id']  = attributeId ;

    setEditCollection([]);      
    setEditAttributes(response);   
    setDialogType(`${e.target.dataset.type}`);
    setDialogOpened(true);
  }


  return (
    <CollectionsContext.Provider value={{collectionsList, dialogOpened, setDialogOpened, dialogType, openDynamicDialog, setDialogType, parentID, setParentID, expanded, setExpanded, openEditDynamicDialog, editCollection, setEditCollection, loadData, editChild, editAttributes }}>
      <Grid container>
        <Grid item container sx={{my: 3}}>
          <Grid item xs={12}>
            <Button onClick={openDynamicDialog} data-type="collection-creation" variant="contained">
              + Create new collection
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CollectionsHolder 
          setCollectionList={setCollectionList}/>
        </Grid>
        <DynamicDialog />
      </Grid>
    </CollectionsContext.Provider>
  );
}


