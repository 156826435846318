import React from "react";

export default function Home() {
  return (
    <div className='container'>
      <p>Check the github repo :</p>
      <a href='https://github.com/flaviuse/mern-authentification'>
        https://github.com/flaviuse/mern-authentification
      </a>
    </div>
  );
}
