
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import  parentListCols  from "../../api/parentListCols";
import Grid from "@mui/material/Grid";



// const AccordionWrappe = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme, expanded }) => ({
//   height: expanded ? '200px': 'auto', // set a fixed height
//   overflowY: "auto",
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
 
// }));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark'
//       ? 'rgba(255, 255, 255, .05)'
//       : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//     position: "sticky",
//     top: 0,
//     zIndex: 1,
//     transition: 'background-color 0.2s ease-out',
//   },
//   '& .MuiAccordionSummary-content.Mui-expanded': {
//     transition: 'background-color 0.2s ease-in',
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: '1px solid rgba(0, 0, 0, .125)',
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
//   '&.Mui-expanded': {
//     height: 'auto',
//     transition: 'height 0.3s ease-in',
//   },
//   '& > *': {
//     marginBottom: theme.spacing(2),
//   },
// }));


export default function ChildrenComponent({productId}) {
  const [productList, setProductList] = useState([]);
  // const [expanded, setExpanded] = React.useState('panel1');
  const columns = parentListCols()

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  // fetch with backend
  useEffect(() => {
    const fetchProductList = async () => {
      try {
        if (productId !== ""){
            const baseUrl = `${process.env.REACT_APP_API_URL}/api/product`; //  backend API endpoint
            const getParentsUrl = `${baseUrl}/get-parents/${productId}`;            
            const response = await fetch(getParentsUrl);
            if (!response.ok) {
              throw new Error("Failed to fetch product list");
            }
            const data = await response.json(); 
            console.log('components obj', data);         
            const mappedData = data.map((product)=>{ // include a specific ID to the object
              // de product.components.filter pro id del padre y de ahi obtener el quantity
              const parentQty = product.components.filter((el) => { return el.productId === productId })
              return{
                ...product,
                id: product._id,
                parentQty: parentQty[0].quantity
              }
            });
            console.log('mappedData', mappedData);
            setProductList(mappedData);
            
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductList();
  }, [productId]);// dependency




return (
  <div style={{display: "flex", height: "400px", width: "100%", margin: "1rem"}}>  
    <Grid container 
          sx={{my: 2}}
    >
        <Grid item xs={12}>
          <h4 className=""> Related Products :</h4>
          <p> Products where contains this child </p>
          
        </Grid>
      <DataGrid
        rows={productList}
        columns={columns}
        components={{Toolbar: GridToolbar}}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {debounceMs: 500},
          },
        }}
        // getRowClassName={(params) => `bg-${params.row.isSellable}`}
      />
    </Grid>
  </div>
)
}

// return (

//   <AccordionWrappe expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
//     <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
//       <Typography>Product Parents</Typography>
//     </AccordionSummary>
//     <AccordionDetails>
//         <List key={productId._id}>
//           {productList.map((product) => (
//             <ListItem alignItems="flex-start">
//               <ListItemAvatar>
//                 <Avatar alt="Remy Sharp" src={product.image} />
//               </ListItemAvatar>
//               <ListItemText
//                 primary={product.sku + ' - ' +product.name}
//                 secondary={
//                   <React.Fragment>
//                     <Typography
//                       sx={{ display: "inline", }}
//                       component="span"
//                       variant="body2"
//                       color="text.primary"
//                     >
//                       Quantity
//                     </Typography>
//                     {product.components.quantity}
//                   </React.Fragment>
//                 }
//               />
//             </ListItem>
//           ))}
//         </List>
//       </AccordionDetails>
//   </AccordionWrappe>
// 