/**
 * List all columns used in the header
 * 
 * @param {component} Button - Component which will be displayed in the delete button
 * @param {function} Delete - Function that will trigger all further actions 
 * 
 * @returns {[<Object>]} - Am array of Objects which correspond to each column
 * 
*/

const columnDisplayData = (Button, handleDelete) => {
    return [
        {
            id: 0,
            field: "sku",
            headerName: "SKU",
            width: 150,
        },
        {
            id: 1,
            field: "name",
            headerName: "Name",
            width: 250,
        },
        {
            id: 2,
            field: "brand",
            headerName: "Brand",
            width: 150,
        },
        {
            id: 4,
            field: "barcode",
            headerName: "Barcode",
            width: 200,
        },
        {
            id: 6,
            field: "supplier",
            headerName: "supplier",
            width: 200,
        },
        {
            id: 8,
            field: "quantity",
            headerName: "Quantity",
        },
        {
            id: 9,
            field: "purchasePrice",
            headerName: "Purchase Price",
            width: 150,
        },
        {
            id: 10,
            field: "total",
            headerName: "Total",
            width: 150,
        },
        {
            id: 10,
            field: "lastPurchasePrice",
            headerName: "Previous purchase price",
            width: 150,
        },
        {
            id: 10,
            field: "lastPurchasePriceDate",
            headerName: "Last Price Date",
            width: 150
        },
        {
            id: 24,
            field: "supplierCode",
            headerName: "Supplier Code",
            width: 150,
        },
        {
            field: 'productId',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {                
                return (
                    <>                    
                    <div><Button
                        variant="contained"
                        color="error"
                        onClick={ ()=> handleDelete(params)}
                    >
                        Delete
                    </Button>
                    </div>
                    </>
                );
            }
        },
    ]
};

export default columnDisplayData;