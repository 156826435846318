let utils = {};

utils.customRound = (value, decimals = false  ) => {
        
    let response
    if (typeof (value) == "string")
        value = value.replace(',', '.').trim()
    else
        value = parseFloat(value);
        
    if (!decimals){
        response = Math.round( Math.round(value * 1000) / 1000 * 100) / 100;
    }
    else {
        response = Math.round(value * 1000) / 1000;
    }
        
    return response.toFixed(2);
        
}

utils.padLeft = function padLeft(nr, n, str) {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
};

utils.quantityTimesPrice = (quantity, price ) => {
    return utils.customRound((utils.customRound(quantity, 2) * utils.customRound(price, 2)),2);
}


module.exports = utils;