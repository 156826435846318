import React, { useEffect, useState } from "react";

const TableHeader = (props) => {
  const [headerData, setHeaderData] = useState(props.headerData);

  useEffect(() => {    
    if (props.headerData) {
      setHeaderData(props.headerData);
    }
  }, [props.headerData]);

  if (headerData.length > 0) {
    return (
      <thead>
        <tr>
          {headerData.map((element, key) => {
            return <td key={key} className="fw-bold">{element.label}</td>;
          })}
        </tr>
      </thead>
    );
  } else {
    return (
      <thead>
        <tr>
          <td> No data available for headers</td>
        </tr>
      </thead>
    );
  }
};
export default TableHeader;
