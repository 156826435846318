import React, {useState, useContext, useEffect} from "react";

// MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CollectionsContext} from "../../context/CollectionsContext.js";
import httpService from "../../services/httpService.js";
import formData from "./formData.js";
import Dropzone from "../Dropzone/Dropzone";
import {makeStyles} from "@material-ui/core/styles";
import {handleClose, setNewVal, handleSave, handleClearError} from './hooks'
import uploadService from "../../services/uploadService";
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 700, // adjust the value as needed
  },
}));

export default function DynamicDialog() {  
  const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);

  const {
    dialogOpened,
    setDialogOpened,
    dialogType,
    setDialogType,
    setParentID,
    parentID,
    editCollection,
    editAttributes,
    setEditCollection,
  } = useContext(CollectionsContext);

  const [currentData, setCurrentData] = useState({});
  const [fileList, setFileList] = useState([]);

  const [dialogData, setDialogData] = useState({
    title: "",
    body: "",
    url: "",
    method: "",
  });

  useEffect(() => {
    const dialogFormData = formData[dialogType];
  
    if (dialogType && dialogOpened && dialogFormData) {
      setDialogData({
        title: dialogFormData.dialogText.title,
        body: dialogFormData.dialogText.body,
        url: dialogFormData.submit.url,
        method: dialogFormData.submit.method,
      });
  
      const collectionMappedData = dialogFormData.fields.reduce((obj, field) => {
        if (editCollection.id) {
          obj[field.name] =
            editCollection[field.name] !== undefined
              ? editCollection[field.name]
              : field.value;
        } else if (editAttributes.attributeValue) {
          obj[field.name] =
            editAttributes[field.name] !== undefined
              ? editAttributes[field.name]
              : editAttributes.attributeValue;
        }
        return obj;
      }, {});
      setCurrentData(collectionMappedData);
    }
  }, [dialogOpened, dialogType, editCollection, editAttributes]);
  


  const classes = useStyles();
  return (
    <Dialog
      open={dialogOpened}
      onClose={()=> {handleClose(setDialogOpened, setFileList, dialogType)}}
      classes={{paper: classes.dialog}}
    >
      <DialogTitle>{dialogData.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogData.body}</DialogContentText>
        {dialogType === "attachment" && ( 
        <>
        <Dropzone setFileList={setFileList} setErrorMessage={setErrorMessage} fileList={fileList} />
        {errorMessage && (          
          <div className="error-message text-danger" onClick={()=> handleClearError(setErrorMessage)}>
            {errorMessage}
          </div>        
        )}
        </>
        )}
        {dialogType != null &&
          dialogType !== "attachment" &&
          formData[dialogType]["fields"].map((field, index) => {
            return (
              <TextField
                autoFocus
                margin="dense"
                id={field.name}
                name={field.name}
                label={field.Label}
                type="text"
                fullWidth
                variant="standard"
                placeholder={field.label}
                onChange={(e)=> {setNewVal(e, currentData, setCurrentData)}}
                value={currentData[field.name]}
              />
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=> {handleClose(setDialogOpened)}}>Cancel</Button>
				<Button
						disabled={loading}
						onClick={() => {
							setLoading(true);
							handleSave(httpService, currentData, formData, parentID, editAttributes, dialogType, editCollection, setEditCollection, setParentID, setDialogOpened, setDialogType, fileList, setFileList, uploadService, setErrorMessage, setLoading)
								.finally(() => setLoading(false));
						}}
						variant="contained"
						color="primary"						
					>
						{loading ? <CircularProgress size={24}  color="primary"/> : 'Save'}
					</Button>
      </DialogActions>
    </Dialog>
  );
}
