
import utils from "../components/utils";

export const getCols = (Checkbox) => ([
    {
      id: 12,
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => {          
          return (<a href={`/products/${params.row._id}`} target="_blank"><img src={params.row.image} width="75%"/></a>)        
      }
    },
    {
      id: 0,
      field: "sku",
      headerName: "SKU",
      width: 100,
      renderCell: (params) => {        
        return (<a href={`/products/${params.row._id}`} target="_blank">{params.row.sku}</a>)
      }
    },
    {
      id: 1,
      field: "name",
      headerName: "Name",
      width: 450,
      renderCell: (params) => {        
        return (<a href={`/products/${params.row._id}`} target="_blank">{params.row.name}</a>)
      }
    },
    {
      id: 2,
      field: "brand",
      headerName: "Brand",
      width: 100,
    },
    {
      id: 17,
      field: "collection_id",
      headerName: "Collection"      
    },
    {
      id: 3,
      field: "category",
      headerName: "Category",
      width: 110,
    },
    {
      id: 4,
      field: "barcode",
      headerName: "Barcode",
      width: 200,      
      hide: true
    },
    {
      id: 6,
      field: "isBOM",
      headerName: "is BOM?",
      width: 80,
      renderCell: (params) => {
        return (          
            <Checkbox
              checked={params.value}
              disabled={true}
              color="primary"
            />          
        );
      }
    },
    {
      id: 11,
      field: "isSellable",
      headerName: "Not available online ?",
      renderCell: (params) => {

        return (
          <Checkbox
            checked={params.value}
            disabled={true}
            color="primary"
          />
        );
      }
    },
    {    
      id: 23,
          field: "isOutOfCollection",
          headerName: "End of lifecycle?",
          renderCell: (params) => {

            return (
              <Checkbox
                checked={params.value}
                disabled={true}
                color="primary"
              />
            );
          }
    }
    ,
    {
      id: 5,
      field: "purchasePrice",
      headerName: "Purchase Price",
    },{
      id: 13,
      field: "lastPurchasePriceDate",
      headerName: "Purchase Price Date",
      width:160
    },    
    {
      id: 7,
      field: "intragroup",
      headerName: "Intragroup",
    },  
    {
      id: 14,
      field: "lastIntragroupPriceDate",
      headerName: "Intragroup Price Date",
      width: 160
    }, 
    {
      id: 8,
      field: "b2b_a",
      headerName: "B2B Premium",
      width:150
    },
    {
      id: 9,
      field: "b2b_b",
      headerName: "B2B Premium Online",
      width:150
    },
    {
      id: 10,
      field: "b2b_c",
      headerName: "B2B Reseller",
      width:150
    },   
    {
      id: 11,
      field: "rsp",
      headerName: "RSP",
      width:80
    },   
    {
      id: 15,
      field: "rsp2",
      headerName: "RSP 2",
      width:80
    },
    {
      id: 18,
      field: "suggested_b2b_a",
      headerName: "Suggested B2B Premium",
      width: 200,      
      hide: true
    },
    {
      id: 19,
      field: "suggested_b2b_b",
      headerName: "Suggested B2B Premium Online",
      width: 200,      
      hide: true
    },
    {
      id: 20,
      field: "suggested_b2b_c",
      headerName: "Suggested B2B Reseller",
      width: 200,      
      hide: true
    },
    {
      id: 21,
      field: "suggested_rsp",
      headerName: "Suggested RSP",
      width: 200,      
      hide: true
    },
    {
      id: 22,
      field: "suggested_rsp2",
      headerName: "Suggested RSP 2",
      width: 200,      
      hide: true
    },
    {
      id: 24,
      field: "supplierCode",
      headerName: "Supplier Code",
      width: 200,
      hide: true
    }
    ,
    {
      id: 25,
      field: "attachedFile",
      headerName: "Attached File",
      width: 200,
      // hide: true,
      renderCell: (params) => {
        return (          
          <Checkbox
          checked={params.value}
          disabled={true}
          color="primary"
          />          
          );
        }
      }
      
    ]);

export const initRowsData = async (httpService) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const productUrl = `${baseUrl}/product/get`;

    // Get Init values for all dropdowns
    try {
      const productList = await httpService.get(productUrl);      
      return await formatRows(productList)

    } catch (error) {
      console.error(error);
    }
  };
  
const getSuggestedPrice = (cost, intragroupCalc, collection) => {
  const response = {
      intragroup: intragroupCalc,
      b2b_a: utils.customRound((intragroupCalc * collection.pricing.b2b_a) / 100, true),
      b2b_b: utils.customRound((intragroupCalc * collection.pricing.b2b_b) / 100, true),
      b2b_c: utils.customRound((intragroupCalc * collection.pricing.b2b_c) / 100, true),
      rsp: utils.customRound((intragroupCalc * collection.pricing.rsp) / 100, true),
      rsp2: utils.customRound((intragroupCalc * collection.pricing.rsp2) / 100, true)
  }
  return response;
}

export const getSumComponent = (value) => {
  
  let totalSum = value.components.reduce((accumulator, current) => {    
    return parseFloat(accumulator) + (parseFloat(current.productId.purchasePrice) * parseFloat(current.quantity))
  }, 0 );
  
  return utils.customRound(totalSum, 2);
}

const formatRows = async (productList) => {
    return productList.data.map((element) => {     

        // Sort price array by date desc
        let tmpPrice = element.purchasePriceHistory.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        let lastPurchasePrice = element.purchasePriceHistory.length > 0 ? utils.customRound(tmpPrice[0].price, true) : '';
        let lastPurchasePriceDate =  element.purchasePriceHistory.length > 0 ? tmpPrice[0].date : '';

        // let purchasePrice = element.purchasePrice ? utils.customRound(element.purchasePrice, true) : '' ;

        // Sort price array by date desc
        let inttmpPrice = element.intragroupHistory.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        
        const purchasePrice = element.isBOM ?  getSumComponent(element) :  utils.customRound(element.purchasePrice, true);        
        const intragroupCalc= element.isBOM ? utils.customRound( ( purchasePrice * element.collection_id.extraFieldsTotal) / 100, 2) : utils.customRound( ( element.purchasePrice * element.collection_id.extraFieldsTotal) / 100, 2) ;                
        //let intlastPurchasePrice = element.intragroupHistory.length > 0 ? utils.customRound(inttmpPrice[0].price, true) : '';
        let intlastPurchasePriceDate =  element.intragroupHistory.length > 0 ? inttmpPrice[0].date : '';

        let collectionName = element.collection_id ? element.collection_id.name : '';
        
        const suggestedPrices = getSuggestedPrice(purchasePrice, intragroupCalc ,element.collection_id)        
        const attachedFile = element.files?.filter((file)=> (file.format.indexOf('image') !== -1)).length > 0 ? true : false
        // cost price for BOM
        const newObject = {
            _id: element._id,
            id: element._id,
            sku: element.sku,
            name: element.name,
            collection_id: collectionName,
            brand: element.brand ? element.brand.name : '',
            category: element.category ? element.category.name : '',
            supplier: element.supplier? element.supplier.name : '',
            color: element.color?.name ? element.color.name : "",
            volume: element.volume,
            barcode: element.barcode,
            weight: element.weight,          
            supplierCode: element.supplierCode,
            intragroup: intragroupCalc,
            b2b_a: element.b2b_a != "0" ? element.b2b_a : "",
            b2b_b: element.b2b_b != "0" ? element.b2b_b : "",
            b2b_c: element.b2b_c != "0" ? element.b2b_c : "",
            rsp: element.rsp  != "0" ? utils.customRound(element.rsp, true) : "",
            rsp2: element.rsp2  != "0" ? utils.customRound(element.rsp2, true) : "",
            isFollow: element.isFollow,
            attachedFile: attachedFile,
            isBOM: element.isBOM,
            isComponent: element.isComponent,
            isSellable: element.isSellable,
            isOutOfCollection: element.isOutOfCollection,
            quantity: element.quantity,            
            height: element.height,
            width: element.width,
            depth: element.depth,
            purchasePrice: purchasePrice,
            image: element.image ? element.image != '0' ? element.image : "" :"",
            // TODO: Is no purchase price is set yet calculate it
            lastPurchasePrice: lastPurchasePrice,
            lastPurchasePriceDate: lastPurchasePriceDate,            
            lastIntragroupPriceDate: intlastPurchasePriceDate,
            suggested_b2b_a: suggestedPrices.b2b_a,
            suggested_b2b_b: suggestedPrices.b2b_b,
            suggested_b2b_c: suggestedPrices.b2b_c,
            suggested_rsp: suggestedPrices.rsp,
            suggested_rsp2: suggestedPrices.rsp2,
        };
        return newObject;
    })
}



