import React, { useEffect, useState } from "react";

function ListTd({ data, headerData }) {
  return (
    <>
      {headerData.map((el, key) => {
        return (
          <td key={key} dangerouslySetInnerHTML={{ __html: data[el.field] ? data[el.field] : data[el.label] }} />
        );
        // return <p>Hola</p>;
      })}
    </>
  );
}

const processData = (data, header, baseURL, selectedTarget ) => {      
  let tmpData = [];
  data.forEach((element, key) => {      
    let tmpValue = [];
    header.forEach((el) => {      
      if (typeof el.default == "undefined") {
        switch (el.field) {
          case "images":
            tmpValue[el.field] = element[el.field][0].large.src;
            //tmpValue[el.field] = `${baseURL}${element.url_key}`;
            break;
          case "url_key":
            // TODO : remove utm when facebook
            tmpValue[el.field] = `${baseURL}${element.url_key}`;
            break;
          case "price.default.value":
            tmpValue[el.field]  = `${element['price']['default']['value'].toFixed(2)} EUR`;
            // tmpValue[el.field] = `${baseURL}${element.url_key}`;
            break;
          case "price.default.original_value":
            // if (typeof(element['price']['default']['original_value']) == "undefined" ) {
            //   console.log(header)
            //   console.log('price', element['price'])
            //   console.log('price', element)
            // }
            tmpValue[el.field]  = `${element['price']['default']['original_value']?.toFixed(2) ?? '-'} EUR`;
            // tmpValue[el.field] = `${baseURL}${element.url_key}`;
          break;
          case "categories[0].name":
            tmpValue[el.field]  = `${element['categories'][0].name}`;
            // tmpValue[el.field] = `${baseURL}${element.url_key}`;
          break;
          case "categories[1].name":
            tmpValue[el.field]  = `${element['categories'][1].name}`;
            // tmpValue[el.field] = `${baseURL}${element.url_key}`;
          break;
          case "categories[2].name":
            tmpValue[el.field]  = `${element['categories'][2].name}`;
            // tmpValue[el.field] = `${baseURL}${element.url_key}`;
          break;
          default:
            var span = document.createElement('span');
            span.innerHTML = element[el.field];                          
            tmpValue[el.field] = span.textContent || span.innerText;
            break;
        }
      } else {          
        tmpValue[el.label] = el.default;          
      }
    });
    tmpData.push(tmpValue);
  });
  return tmpData;
};


const TableBody = (props) => {
  const [tableData, setTableData] = useState([]);
  // const [headerData, setHeaderData] = useState(props.headerData);  


  useEffect(() => {
    if (props.tableData.length > 0 ) {
      setTableData(processData(props.tableData, props.headerData,  props.baseURL, props.selectedTarget));      
    }
  }, [props]);

  if (tableData.length === 0 )
    return (<>Loading Data...</>)
  
  return (
    <tbody>
      {tableData.map((el, key) => {
        return (
          <tr key={key + "-" + Math.random(0, 1000)}>
            <ListTd data={el} headerData={props.headerData} />
          </tr>
        );
      })}
    </tbody>
  );
};
export default TableBody;
