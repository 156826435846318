import { Button, CircularProgress } from "@mui/material";
import Save from "@mui/icons-material/Save";

export const UploadButton = ({ loading, onClick }) => {
    if (loading) {
      return <CircularProgress size={24} />;
    }
  
    return (
      <Button
        onClick={onClick}
        variant="contained"
        color="primary"
        startIcon={<Save />}
        disabled={loading}
      >
        Save
      </Button>
    );
  };