const websitesData = [ 
  {
    site: "wookids.de",
    indexName: "abi_wookids_de_shopinvader_variant_de_de",
    domain: "https://wookids.de/",
  },
  {
    site: "wookids.eu",
    indexName: "abi_wookids_eu_shopinvader_variant_fr_fr",
    domain: "https://wookids.eu/",
  },
  {
    site: "wookids.eu/en",
    indexName: "abi_wookids_eu_shopinvader_variant_en_us",
    domain: "https://wookids.eu/en/",
  },
  {
    site: "wookids.eu/es",
    indexName: "abi_wookids_eu_shopinvader_variant_es_es",
    domain: "https://wookids.eu/es/",
  },
  {
    site: "abitare-kids.es",
    indexName: "abi_kids_es_shopinvader_variant_es_es",
    domain: "https://abitare-kids.es/",
  },
  {
    site: "abitare-living.lu",
    indexName: "abi_living_lu_shopinvader_variant_fr_fr",
    domain: "https://abitare-living.lu/",
  },
  {
    site: "abitare-kids.de",
    indexName: "abi_kids_de_shopinvader_variant_de_de",
    domain: "https://abitare-kids.de/",
  },
  {
    site: "abitare-kids.lu",
    indexName: "abi_kids_lu_shopinvader_variant_fr_fr",
    domain: "https://abitare-kids.lu/",
  },
  {
    site: "abitare-kids.fr",
    indexName: "abi_kids_fr_shopinvader_variant_fr_fr",
    domain: "https://abitare-kids.fr/",
  }
];

module.exports = websitesData;
