// fileUtils.js

export function createFilePreviews(files) {
    return files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }
  
  export function filterAllowedFileTypes(files, allowedTypes) {
    return files.filter((file) => allowedTypes.includes(file.type));
  }
  