import React, {useState, useRef} from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArticleIcon from "@mui/icons-material/Article";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SaveIcon from '@mui/icons-material/Save';
import Notifications from "../../components/Notification.jsx";
import {exporterContext} from "../../context/Context.js";


const Input = styled("input")({
  display: "none",
});

export default function ImporterPage() {
  const [tpye, setType] = React.useState("");
  const inputEl = useRef(null);
  const formEL = useRef(null);
  

  const [notificationMessage, setNotificationMessage] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const notification = (message) => {    
    setNotificationMessage(message);   
    setOpenNotification(true);   
  };


  
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const baseUrl = `${process.env.REACT_APP_API_URL}/api`;      
  const fileUploadURL = `${baseUrl}/importer/upload`;

  const butttonSave = (e) => {
    
    formEL.current.submit();
  }
  const formSubmitterUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();    
    formData.append("file", inputEl.current.files[0]);
    formData.append("type", tpye);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", fileUploadURL);
    xhr.send(formData);
    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log(xhr.responseText);
        const response = JSON.parse(xhr.responseText);
        console.log(response.updatedFiles.length);
        notification(`
        
            File uploaded successfully, 
            ${response.successFiles.length} products created
            ${response.updatedFiles.length} products updated
            ${response.errorFiles.length} products failed


        `);
        console.log(xhr.response);
      } else {
        console.log(xhr.response);
      }
    };
  };

  return (
    <>
    <exporterContext.Provider value={{ notificationMessage, openNotification, setOpenNotification, notification}}>
      <form action={fileUploadURL} method="POST" encType="multipart/form-data" id="importForm" onSubmit={formSubmitterUpload} ref={formEL} >
        <Box sx={{minWidth: 120}} mb={2}>
            <h1>File Importer</h1>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">File Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="type"
              value={tpye}
              name="type"
              label="File Type"
              onChange={handleChange}
            >
              <MenuItem value="categories">Categories</MenuItem>              
              <MenuItem value="products">Products</MenuItem>              
            </Select>
          </FormControl>
        </Box>

        <label htmlFor="contained-button-file">
          <Input
            accept=".csv"
            name="file"
            id="contained-button-file"            
            type="file"
            ref={inputEl} 
          />
          <Button
            variant="outlined"
            component="span"
            startIcon={<ArticleIcon />}
          >
            Select File
          </Button>

        </label>        
      
          <Button          
            type="button"
            variant="contained"
            component="span"
            startIcon={<SaveIcon />}
            sx={{ marginLeft: 2 }}
            onClick={formSubmitterUpload}
          >
            Save
          </Button>

       
      </form>
      <Notifications />
      </exporterContext.Provider>
    </>
  );
}
