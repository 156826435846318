import React from "react";
import TableTd from "../../../components/TableTd";
import {useFetchData} from "./hooks/useFetchData.js";
import {updateAdmin} from "./helpers/userPage.helpers.js";
import Switch from '@mui/material/Switch';


export default function UsersPage() {
  const usersData  = useFetchData();  
  
  const handleChange = async (event, target) => {        
    const response = await updateAdmin(event?.target?.attributes?.userid?.value);    
    window.location.reload();
  }
  
  return (
    <>
      <table style={{width: "100%","tableBoder": "1px solid #ccc"}}>
        <thead  style={{borderBottom: "1px solid black"}}>
        <tr>
          <td><b>User</b></td>
          <td><b>E-mail</b></td>
          <td><b>is Admin ?</b></td>
        </tr>
        </thead>
        <tbody>          
          {usersData?.usersList &&
            usersData.usersList.map((user) => {
              console.log(user);
              let label = { inputProps: { 'aria-label': 'Is Admin ? ', userid: user._id }, checked: false  };
              if ( user.isAdmin ) {
                label.checked = true;
              } 
              console.log('label', label);
              return (
                <tr>
                  <TableTd key={user._id} label={user.username} />
                  <TableTd key={user._id + "-name"} label={user.email} />
                  <TableTd key={user._id + "-isAdmin"} label={<Switch {...label}  onChange={handleChange} />}  />
                </tr>
              );
            })}
        </tbody>
      </table>     
    </>
  );
}
