import React, { useEffect, useState } from "react";
import IndexSelector from "../../components/Algolia/IndexSelector.jsx";
import Target from "../../components/Algolia/Target.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import TableHolder from "../../components/Algolia/TableHolder.jsx";
import websitesData from "../../data/websitesData.js";
import headerData from "../../data/headerData.js";

export default function Algolia() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedTarget, setSelectedTarget] = useState("google");    
    const [baseURL, setbaseURL] = useState();
    const [headerObject, setheaderObject] = useState(headerData.google);
  
    const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    if (selectedTarget === "facebook") {
      setheaderObject(headerData.facebook);
    } else if ( selectedTarget === "google") {
      setheaderObject(headerData.google);
    } 
    
  }, [selectedTarget]);

  function sendDataToParent(index, domain) {
    // if (!domain) throw new Error("Error, no hay dominio seleccionado"); // Is domain available ?
    if ( index !== "0"){
      setbaseURL(domain);
      setSelectedIndex(index);
      let domainHolder = document.querySelector(".domain-holder");
      if (domainHolder) domainHolder.value = domain;
    }
  }


  
  function copyToClipboard(e) {
    navigator.clipboard.writeText(document.querySelector('#product-data').innerText).then(
      function(){          
          setCopySuccess('Copied!');
      })
    .catch(
       function() {
          alert("err"); // error
    });
  };


    return (
        <div className="App container-fluid g-0">
        <div className="row bg-light p-3">
          <div className="col-3">
            <IndexSelector
              sendDataToParent={sendDataToParent}
              websitesData={websitesData}
              headerData={headerObject}
            />
          </div>
          <div className="col-3">
            <Target sendDataToParentTarget={setSelectedTarget} />
          </div>
          <div className="col-3">
            <input
              className="form-control domain-holder"
              placeholder="https://wookids.es"
              name="txt-base-url"
              disabled="disabled"
            />
          </div>
          <div className="col-3">
          {
         /* Logical shortcut for only displaying the 
            button if the copy command exists */
         document.queryCommandSupported('copy') &&
          <div>
            <button className="btn btn-primary" onClick={copyToClipboard}>Copy</button> 
            {copySuccess}
          </div>
        }
      
          </div>
        </div>
  
        <div className="container-fluid" id="product-data">
          <table >
            <TableHolder            
              className="product-data table"
              selectedIndex={selectedIndex}
              baseURL={baseURL}
              headerData={headerObject}
              selectedTarget={selectedTarget}
            />
          </table>
        </div>
      </div>
    )
}