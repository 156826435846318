import http from "../services/httpService";
import fetchCollectionsList from './collectionApi'


const postLogin = (user) => http.post("/auth/login", user);
const sendResetPasswordLink = (email) => http.post("/auth/login/forgot", { email });
const resetPassword = (password, token) => http.post(`/auth/login/reset/${token}`, { password });
const postLogout = () => http.post("/auth/logout");
const postRegister = (user) => http.post("/auth/register", user);
const getConfirmation = (token) => http.get(`/auth/confirmation/${token}`);
const resendConfirmation = (email) => http.post("/auth/resend", { email });
const resetRegister = (email) => http.post("/auth/register/reset", { email });
const getUser = () => http.get("/user");
const getUsers = () => http.get("/users");
const updateUserAdmin = (userId) => http.post(`/user/update/admin/`, { userId });



// Collection 
const saveCollectionCommentValues = ( value, id ) =>http.post("/collection/comments", { value , id });
const getCollectionById = (id) => http.get(`/collection/get/${ id }`);
const getCollectionList = () => (fetchCollectionsList());
const removeCollectionAttachment = (parentId, fileId) => http.post(`/collection/removeAttachment/`, { parentId, fileId });

// Product 
const saveProductCommentValues = ( value, id ) =>http.post("/product/comments/save", { value , id });
const removeProductAttachment = (parentId, fileId) => http.post(`/product/removeAttachment/`, { parentId, fileId });



export {
  postLogin,
  sendResetPasswordLink,
  resetPassword,
  postLogout,
  postRegister,
  getConfirmation,
  resendConfirmation,
  getUser,
  resetRegister,
  saveCollectionCommentValues,
  getUsers,
  updateUserAdmin,
  getCollectionById,
  saveProductCommentValues,
  getCollectionList,
  removeCollectionAttachment,
  removeProductAttachment
};
