// Dropzone.js

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import pdfFile from "../../assets/pdf-file.png";
import { createFilePreviews, filterAllowedFileTypes } from "./fileUtils";


const Dropzone = ({ fileList, setFileList, setErrorMessage }) => {
  const [files, setFiles] = useState([]);

  const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"];

  const onDrop = (acceptedFiles) => {
    setErrorMessage(null);
    const newFiles = filterAllowedFileTypes(acceptedFiles, allowedFileTypes);
    const filesWithPreviews = createFilePreviews(newFiles);
    setFiles([...files, ...filesWithPreviews]);
    setFileList([...files, ...filesWithPreviews]);
  };

  const handleRemove = (file) => {
    setFiles(files.filter((f) => f !== file));
    setFileList(fileList.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedFileTypes,
    onDrop: onDrop,
  });

  return (
    <>
      <div {...getRootProps({ className: "dropzone my-3" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className="preview-container m-3">
        {files.map((file) => (
          <div key={file.path} className="preview ">
            {file.type === "application/pdf" ? (
              <img src={pdfFile} alt={file.name} width="100px" className="placeholder-file" />
            ) : (
              <img src={file.preview} alt="preview" />
            )}
            <small>{file.name}</small>
            <IconButton color="error" onClick={() => handleRemove(file)} className="mt-2">
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>    
    </>
  );
};

export default Dropzone;
