import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import * as Yup from "yup";
import {Formik, Form, Field, ErrorMessage} from "formik";
import {attemptLogin} from "../store/thunks/auth";
import {useDispatch, useSelector} from "react-redux";
import {Error} from "../components";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function LoginPage() {  

  const {isAuth} = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");

  const dispatch = useDispatch();

  const initialValues = {
    username: "",
    password: "",
  };
 

  const onSubmit = (values) => {
    dispatch(attemptLogin(values)).catch(({response}) => {      
      if (response.data.message) {
        setServerError(response.data.message);
      }
    });
  };

  const validationSchema = Yup.object({
    username: Yup
      .string('Enter your username')
      .min(5, 'Username should be of minimum 5 characters length')
      .required('Username is required'),
    password: Yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });

  
  const formik = Formik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const formitData = formik.props.value

  const WithMaterialUI = () => {
    return (
     
        <form
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1.5rem", padding: "1rem" }}
          spacing={2}
          autoComplete="off"
          onSubmit={formitData.handleSubmit}
        >
          
          <TextField
            fullWidth            
            id="username"
            name="username"
            label="Username"
            variant="outlined"
            placeholder="Username"
            value={formitData.values.username}
            onChange={formitData.handleChange}
            error={formitData.touched.username && Boolean(formitData.errors.username)}
            helperText={formitData.touched.username && formitData.errors.username}
          />

          <TextField
            fullWidth            
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            placeholder="Password"
            value={formitData.values.password}
            onChange={formitData.handleChange}
            error={formitData.touched.password && Boolean(formitData.errors.password)}
            helperText={formitData.touched.password && formitData.errors.password}
          />
          
          
          <FormControl fullWidth >
            <Button color="primary" variant="contained" type="submit" fullWidth >
              Login
            </Button>
          </FormControl>

              <div>
              {serverError && <Error>{serverError}</Error>}
              </div> 

              <div >
                <b>Or </b>
                <Link to="/register">Sign Up</Link>
               </div> 
        </form>
        
    );
  };


  return isAuth ? (
    <Redirect to="/home" />
  ) : (
    <Grid container style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      <Grid item container>
        <Grid item xs={12} md={5} sx={{ mx: "auto" }}>
          <Card>
              <CardContent>
                {WithMaterialUI()}
              </CardContent>
            </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
