import { getUser } from "../../api/index";
import { setUser, resetUser } from "./../actions/user";

export const attemptGetUser = () => (dispatch) =>
  getUser()
    .then((data) => {      
      if (data.data.user) {        
        dispatch(setUser(data.data.user));
      } else {        
        dispatch(resetUser());
      }
    })
    .catch(() => {
      console.log('entro por catch')
      dispatch(resetUser());
    });
