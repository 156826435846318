import React, {useState, useEffect, useContext} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import httpService from "../services/httpService";
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import utils from "./utils";

import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';
import Button from "@mui/material/Button";

import {exporterContext} from "../context/Context.js";

export default function LinkingProduct({user}) {
    const {rows, setRows, setNewComponentOpen, productId, initRowsData, values, setIsBOM } = useContext(exporterContext);
    const [bomProductList, setBomProductList] = useState([]);
    const [producData, setProducData] = useState([]);
    const [childQuantity, setChildQuantity] = useState(1);

    const filterOptions = createFilterOptions({
        matchFrom: "start",
        stringify: (option) => option.sku,
      });

    const skuChange = (e, newValue) => {
        if( newValue ){            
            setProducData(newValue);
        }
    };
    
  useEffect(() => {    
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    let url = `${baseUrl}/product/get`;    
    // Make request to creation method
    httpService.get(url, values).then(async function (response) {
        if (response.data.length > 0) {
        setBomProductList(response.data);
        }
    });      
  });


  const butttonSave = (e) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const url = `${baseUrl}/product/child/add/${productId}`;
    const params = {
      childId: producData._id,
      quantity: childQuantity
    };   

    // Make request to creation method
    httpService
    .post(url, params)
    .then(async function (response) {        
      // handle success                
        setIsBOM(false);
        setIsBOM(true);     
        setNewComponentOpen(false);     

    }) 
    .catch(function (error) {
      // handle error
      console.log(error);
    });
    }

  const onQuantityChange = (e) => {
    setChildQuantity(e.target.value);
  }
  
    return (
      <>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Autocomplete
              id="filter-demo"
              maxWidth="md"
              options={bomProductList}
              getOptionLabel={(option) => {
                return option.sku;
              }}
              filterOptions={filterOptions}
              onChange={skuChange}
              renderInput={(params) => <TextField {...params} label="SKU" />}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <div>
              <b>Product Name :</b>
            </div>
            {producData.name ? producData.name : null}
          </Grid>

          <Grid item xs={12} mb={3}>
            <TextField
              id="purchasePrice"
              label="Purchase Price"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
              value={
                producData.purchasePrice
                  ? utils.customRound(producData.purchasePrice)
                  : ""
              }
              // onChange={set("purchasePrice")}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="quantity"
              inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              label="Quantity"
              variant="outlined"
              fullWidth
              value={childQuantity ? childQuantity : ""}
              onChange={onQuantityChange}
              required
            />
          </Grid>
          <Grid itme xs={12}>
            {user.isAdmin && (
              <Button
                type="submit"
                variant="contained"
                component="span"
                startIcon={<SaveIcon />}
                sx={{marginTop: 2}}
                onClick={butttonSave}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </>
    );
}