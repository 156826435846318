import utils from '../../components/utils';
import httpService from '../../services/httpService'

export const initRowsData = async (productId) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;    
    if (productId !== "") {
        const productUrl = `${baseUrl}/product/get/${productId}`;

        // Get Init values for all dropdowns
        try {
            let productList = await httpService.get(productUrl);            
            if (productList.data.length > 0) {
                let tmpList = productList.data[0].components;
                let tmpProducts = [];

                // Retriving product data;
                tmpList.forEach((element) => {
                    // Sort price array by date desc
                    let tmpPrice = element.productId.purchasePriceHistory.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date);
                    });
                    let lastPurchasePrice = (element.productId.purchasePriceHistory.length > 0) ? utils.customRound(tmpPrice[0].price, true) : '';

                    let lastPurchasePriceDate = element.productId.purchasePriceHistory.length > 0 ? tmpPrice[0].date : '';

                    const newObject = {
                        id: element._id,
                        productId: element.productId._id,
                        sku: element.productId.sku,
                        name: element.productId.name,
                        brand: element.productId.brand.name,
                        category: element.productId.category.name,
                        barcode: element.productId.barcode,
                        volume: element.productId.volume,
                        isSellable: element.productId.isSellable,
                        supplier: element.productId.supplier.name,
                        quantity: element.quantity,
                        total: utils.customRound(utils.quantityTimesPrice(element.quantity, element.productId.purchasePrice)),
                        cost: element.productId.cost,
                        intragroup: element.productId.intragroup,
                        b2b_a: element.productId.b2b_a,
                        b2b_b: element.productId.b2b_b,
                        b2b_c: utils.customRound(element.productId.b2b_c, true),
                        purchasePrice: utils.customRound(element.productId.purchasePrice, true),
                        lastPurchasePrice: lastPurchasePrice,
                        lastPurchasePriceDate: lastPurchasePriceDate,
                        attachedFile: element.attachedFile
                    };
                    tmpProducts.push(newObject);
                });
                return tmpProducts;
            } else {
                throw ("No product found");
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    return [];
}
