const uploadService = async (parentID, fileList, path) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
        formData.append("files", fileList[i]);
    }
    formData.append("parentId", parentID);
    const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: formData
    });
    if (response.status !== 200) {
        throw new Error("Failed to upload file.");
    }    
    const data = await response.json();    
    return data;
};

export default uploadService;
