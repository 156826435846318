import React, {useState, useContext, useEffect} from "react";
import { createTheme  } from "@mui/material/styles";
import { ThemeProvider } from '@mui/styles'

import MUIRichTextEditor from "mui-rte";
import { saveCollectionCommentValues, saveProductCommentValues } from "../api/index";


export default function TextEditorCreator({ type, element  }) {

    const myTheme = createTheme({
        overrides: {
          MUIRichTextEditor: {

              root: {
                  backgroundColor: "#fff",
                  margin: "auto",
                  display: "block",
                  marginTop: "1rem"
              },
              container: {
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #ccc",
              },
              editor: {
                  backgroundColor: "white",
                  padding: "10px",
                  height: "200px",
                  maxHeight: "200px",
                  overflow: "auto"                       
              },
              toolbar: {
                  borderTop: "1px solid gray",
                  backgroundColor: "#ebebeb",
              },
              placeHolder: {
                  backgroundColor: "transparent",
                  paddingLeft: "10px",
                  width: "inherit",
              },
              anchorLink: {
                  color: "#333333",
                  textDecoration: "underline"
              }
        }
      },

        // Set up your custom MUI theme here
      });

  return (
    <ThemeProvider theme={myTheme}>
        <MUIRichTextEditor
        label="Type something here..."
        onSave={async (value) => {
            if ( type === "collection" ){                
                await saveCollectionCommentValues(value, element._id);
            }
            else if ( type === "product") {                
                const response = await saveProductCommentValues(value, element._id);
                console.log('response =', response);
            }
        }}
        value={element.comments || ""}
        />
  </ThemeProvider>
  );
}
