import React, {useState, useEffect, useContext} from "react";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import httpService from "../../services/httpService";
import columnDisplayData from "./columnDisplayData";
import RenderConfirmDialog from "./RenderConfirmDialog";
import Button from "@mui/material/Button";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import {exporterContext} from "../../context/Context.js";

export default function ProductComponents({user}) {
  const {rows, setRows, setNewComponentOpen, productId, initRowsData, setIsBOM} = useContext(exporterContext);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [produtToDelete, setProductToDelte] = useState({});

  // Get Coumns from outsource
  useState(async () => {    
    if (productId !== "") {      
      setRows(await initRowsData(productId));
    }
  }, [productId]);

  const removeRow = (data) => {    
    setProductToDelte(data);
    setIsDeleteDialogOpened(true);
    // setNbRows((x) => Math.max(0, x - 1))
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpened(false);
  }

  const handleYesDeleteDialog = () => {    
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const url = `${baseUrl}/product/child/remove/${productId}`;
    const params = {
      childId: produtToDelete.value
    };   

    // Make request to creation method
    httpService
    .post(url, params)
    .then(async function (response) {        
      // handle success                
        setIsBOM(false);
        setIsBOM(true);     
        setIsDeleteDialogOpened(false);
        setProductToDelte({});
    }) 
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }

  const cols = columnDisplayData(Button, removeRow);

  // const [nbRows, setNbRows] = React.useState(cols.length || 0);

  const handleClickOpen = () => {
    setNewComponentOpen(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h4 className="mt-3 mb-3"> Components :</h4>
          <p> Products used to build the BOM. </p>
          <hr></hr>
        </Grid>
        <Grid item xs={12}>
          {rows.length > 0 && (
            <DataGrid
              style={{height: 450, width: "100%"}}
              rows={rows}
              columns={cols}
              // components={{Toolbar: customToolBar}}
              components={{Toolbar: GridToolbar}}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {debounceMs: 500},
                }}}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3} sx={{mx: "auto"}}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{xs: 1, sm: 2, md: 3}}
            sx={{my: 2}}
          >
            {/* <Grid item xs={12} md={6}>
      <Button variant="outlined" onClick={removeRow} sx={{width: "100%"}}>
          Remove component
      </Button>
    </Grid> */}
            {user.isAdmin && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={handleClickOpen}
                  sx={{width: "100%"}}
                >
                  Add product
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <RenderConfirmDialog
        iseDeleteDialogOpened={isDeleteDialogOpened}
        productData={""}
        handleYesDeleteDialog={handleYesDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
      />
    </>
  );
}
