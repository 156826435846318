export const handleUpload = async (parentID, fileList, setErrorMessage, uploadService, handleUploadCallback) => {    
    const path= '/product/attachFile'
    if (fileList.length === 0) {
        setErrorMessage("Please select a file to upload.");
        return;
    }
    try {
        const data = await uploadService(parentID, fileList, path);        
        handleUploadCallback(data)
    } catch (error) {
        setErrorMessage("Failed to upload file.");
    }
};

export const handleClearError = (setErrorMessage) => {
    setErrorMessage(null);
};

// export const handleClose = (setDialogOpened, setFileList, dialogType) => {
//     setDialogOpened(false);
//     if(dialogType === "attachment") setFileList([]);
// };
