import utils from "../components/utils";

export const getSumComponent = (rows, values) => {
    let totalSum = values.isComponent ? values.purchasePrice : rows.reduce((accumulator, current) => (parseFloat(accumulator) + parseFloat(current.total)), 0);
    return utils.customRound(totalSum, 2);
}

export const getIntragroup = (values, collection, componentSum) => {
    return values.isBOM ? utils.customRound((componentSum * collection.extraFieldsTotal) / 100, true) : utils.customRound((values.purchasePrice * collection.extraFieldsTotal) / 100, 2)
}

export const setPrices = async (httpService, url, data, notification) => {
    return httpService.post(url, data)
        .then(res => {
            notification("Product updated successfully 👍🏽");
        })
        .catch(err => {
            notification("Something went wrong 👎🏽", 'error');
            console.log(err);
        });
}

export const getSuggestedPrice = (cost, intragroupCalc, collection) => {
    const response = {
        intragroup: intragroupCalc,
        b2b_a: utils.customRound((intragroupCalc * collection.pricing.b2b_a) / 100, true),
        b2b_b: utils.customRound((intragroupCalc * collection.pricing.b2b_b) / 100, true),
        b2b_c: utils.customRound((intragroupCalc * collection.pricing.b2b_c) / 100, true),
        rsp: utils.customRound((intragroupCalc * collection.pricing.rsp) / 100, true),
        rsp2: utils.customRound((intragroupCalc * collection.pricing.rsp2) / 100, true)
    }
    return response;
}