import React from "react";

const Target = ({sendDataToParentTarget}) => {  


  const changeHandler = (e) =>{
    sendDataToParentTarget( e.target.value );
  }

  
  return (
    <select className="cmb-target-file form-select" onChange={changeHandler}>
      <option value="google">Google</option>      
      <option value="facebook">Facebook</option>
    </select>
  );
};

export default Target;
