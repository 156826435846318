export const handleClose = (setDialogOpened, setFileList, dialogType) => {
    setDialogOpened(false);
    if(dialogType === "attachment") setFileList([]);
};

export const setNewVal = (e, currentData, setCurrentData) => {
    setCurrentData({ ...currentData, [e.target.name]: e.target.value });
};

export const handleSave = async (httpService, currentData, formData, parentID, editAttributes, dialogType, editCollection, setEditCollection, setParentID, setDialogOpened, setDialogType, fileList, setFileList, uploadService, setErrorMessage, setLoading) => {
    let submitData = currentData;
    setLoading(true)
    if (parentID !== 0) {
        submitData.parentId = parentID;
    }
    let saveUrl = formData[dialogType].submit["url"];

    if (editCollection.name || editAttributes.name) {
        saveUrl = formData[dialogType].edit["url"];
        submitData.id = editCollection.id || editAttributes.id;
    }

    try {
        if (dialogType === "attachment") {
           const newFileList =  await handleUpload(parentID, fileList, setErrorMessage, uploadService);          
          // props.setValues((oldValues) => ({...oldValues, 'files': newFileList.data}));
          setDialogType(null)
          setDialogOpened(false);
        } else {
            await httpService.post(saveUrl, submitData);
            setEditCollection([]);
            setParentID(0);
            setDialogOpened(false);
            setDialogType(null);
        }
    } catch (error) {
        console.error(error);
        setErrorMessage("Failed to save data.");
    }
};


export const handleUpload = async (parentID, fileList, setErrorMessage, uploadService) => {    
    const path = '/collection/attachFile';
    if (fileList.length === 0) {
        setErrorMessage("Please select a file to upload.");
        return;
    }
    try {
        const data = await uploadService(parentID, fileList, path);
        return data;
    } catch (error) {
        setErrorMessage("Failed to upload file.");
    }
};





export const handleClearError = (setErrorMessage) => {
    setErrorMessage(null);
};