import React, {useState, useContext, useEffect } from "react";
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert';
import {exporterContext} from "../context/Context.js";
import Button from "@mui/material/Button";
import Snackbar from '@mui/material/Snackbar';


export default function Notifications () {
    //GET CONTEXT VARS
    const {             
       notificationMessage,
       openNotification,
       setOpenNotification
     } = useContext(exporterContext);

     const  vertical = 'bottom';
     const  horizontal = 'right';          
  
      return (          
          <>
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openNotification}
        severity="success"
        onClose={
            (event, reason) => {            
            setOpenNotification(false)
        }
        }

        autoHideDuration={4000}      
        key={vertical + horizontal}
      >
          <Alert onClose={(event, reason) => {            
            setOpenNotification(false);            
          }}  severity="success" sx={{ width: '100%' }}>
            {notificationMessage}
            </Alert>
    </Snackbar>      
    </>
      )

}