import {useEffect, useState} from "react";
import {getUsers} from "../../../../api";

export const useFetchData = () => {
  const [usersList, setUsersList] = useState([]);
  
  useEffect( () => {
    async function fetchData() {
        const userListResponse = await getUsers();          
        setUsersList(userListResponse.data);          
    }
    fetchData()
  }, []);

  return {usersList};
};