import React, {useState, useEffect, useContext} from "react";
import Grid from "@mui/material/Grid";
import {exporterContext} from "../context/Context.js";
import InputAdornment from '@mui/material/InputAdornment';
import utils from "./utils";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import httpService from "./../services/httpService.js";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { useFormControl } from '@mui/material/FormControl';

import { getSumComponent, getIntragroup, setPrices, getSuggestedPrice } from "../api/pricingDetails.js";
import TableTd from "./TableTd.jsx";
import DisplaySuggested from "./DisplaySuggested.jsx";


export default function PricingDetails({ user }) {
    const {rows, collection, values, set, baseUrl, productId, notification, setValues } =
    useContext(exporterContext);

    const [componentSum, setComponentSum] = useState(0);
    const [intragroup, setIntragroup] = useState(0);
    const [suggested, setSuggested] = useState({});


    useEffect(() => {
      const intragroupCalc=  getIntragroup(values, collection, componentSum );
      const cost = values.isBOM ? getSumComponent(rows, values) : utils.customRound(values.purchasePrice, true);
      setSuggested(getSuggestedPrice(cost, intragroupCalc, collection));      
      setComponentSum(cost);
      setIntragroup(intragroupCalc);
    }, [componentSum, collection, rows]);


    const updatePrices = async () => {
      const url = `${baseUrl}/product/update/${productId}`;
      const data = {
        intragroup: intragroup,
        cost: componentSum,
        b2b_a: values.b2b_a,
        b2b_b: values.b2b_b,
        b2b_c: values.b2b_c,
        rsp: values.rsp,
        rsp2: values.rsp2,
    }
    if ( values.isBOM) {
      data.purchasePrice = componentSum;
    }
      await setPrices(httpService, url, data, notification);
  }



    return (
      <>
        <Grid container style={{margin: "1rem auto"}}>
          <Grid item xs={12}>
            <h4 className="mt-5 mb-3">Pricing :</h4>
            <hr></hr>
            <div>
              <Grid container>
                <Grid item xs={6}>
                  <b>Cost:</b> {componentSum} €{" "}
                  <input type="hidden" name="cost" value={componentSum} />
                </Grid>
                <Grid item xs={6}>
                  <b>
                    Intragroup (
                    {collection ? collection.extraFieldsTotal : null}%) :
                  </b>
                  {intragroup} € <br />
                  <small style={{fontSize: "10px"}}>
                    {values.lastIntragroupPriceDate &&
                      values.lastIntragroupPriceDate}
                  </small>
                </Grid>
              </Grid>

              <br />
              <br />
              <table style={{width: "100%"}} cellPadding="15px">
                <thead>
                  <tr>
                    <TableTd label="Channel" />
                    <TableTd
                      label={
                        <>
                          B2B Premium <b>({collection.pricing.b2b_a}%)</b>
                        </>
                      }
                    />
                    <TableTd
                      label={
                        <>
                          B2B Premium Online <b>({collection.pricing.b2b_b}%)</b>
                        </>
                      }
                    />
                    <TableTd
                      label={
                        <>
                          B2B Reseller <b>({collection.pricing.b2b_c}%)</b>
                        </>
                      }
                    />
                    <TableTd
                      label={
                        <>
                          RSP 1<b>({collection.pricing.rsp}%)</b>
                        </>
                      }
                    />
                    <TableTd
                      label={
                        <>
                          RSP 2<b>({collection.pricing.rsp2}%)</b>
                        </>
                      }
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {suggested && (
                      <DisplaySuggested
                        suggested={suggested}
                        setValues={setValues}
                      />
                    )}
                  </tr>
                  {
                    <tr>
                      <td>Output Prices</td>
                      <td>
                        <TextField
                          label="Pricing A"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            setValues((oldValues) => ({
                              ...oldValues,
                              ["b2b_a"]: e.target.value.replace(",", "."),
                            }));
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          value={
                            (values.b2b_a == "0" || values.b2b_a == "0.00") &&
                            collection
                              ? suggested.b2b_a
                              : values.b2b_a
                          }
                        />
                        <FormHelperText id="pricing-b2b-a-helper-text">
                          {values.b2b_a &&
                          values.b2b_a != "0" &&
                          values.b2b_a !== "0.00"
                            ? ((values.b2b_a * 100) / intragroup).toFixed(2) +
                              " %"
                            : ""}
                        </FormHelperText>
                      </td>
                      <td>
                        <TextField
                          label="Pricin B"
                          variant="outlined"
                          fullWidth
                          id="b2b_b"
                          onChange={(e) => {
                            setValues((oldValues) => ({
                              ...oldValues,
                              ["b2b_b"]: e.target.value.replace(",", "."),
                            }));
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                          value={
                            (values.b2b_b == "0" || values.b2b_b == "0.00") &&
                            collection
                              ? suggested.b2b_b
                              : values.b2b_b
                          }
                        />
                        <FormHelperText id="pricing-b2b-b-helper-text">
                          {values.b2b_b != "0" && values.b2b_b !== "0.00"
                            ? ((values.b2b_b * 100) / intragroup).toFixed(2) +
                              " %"
                            : ""}
                        </FormHelperText>
                      </td>
                      <td>
                        <TextField
                          label="Pricing C "
                          id="b2b_c"
                          variant="outlined"
                          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          value={
                            (values.b2b_c == "0" || values.b2b_c == "0.00") &&
                            collection
                              ? suggested.b2b_c
                              : values.b2b_c
                          }
                          onChange={(e) => {
                            setValues((oldValues) => ({
                              ...oldValues,
                              ["b2b_c"]: e.target.value.replace(",", "."),
                            }));
                          }}
                          // onFocus={set("b2b_c")}
                          aria-describedby="pricing-b2b-c-helper-text"
                        />

                        <FormHelperText id="pricing-b2b-c-helper-text">
                          {values.b2b_c != "0" && values.b2b_c !== "0.00"
                            ? ((values.b2b_c * 100) / intragroup).toFixed(2) +
                              " %"
                            : ""}
                        </FormHelperText>
                      </td>
                      <td>
                        <TextField
                          label="RSP"
                          id="rsp"
                          variant="outlined"
                          // value={values.rsp}
                          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          value={
                            (values.rsp === "0" || values.rsp === "0.00") &&
                            collection
                              ? suggested.rsp
                              : values.rsp
                          }
                          onChange={(e) => {
                            setValues((oldValues) => ({
                              ...oldValues,
                              ["rsp"]: e.target.value.replace(",", "."),
                            }));
                          }}
                          // onFocusOut={set("rsp")}
                          aria-describedby="pricing-rsp-helper-text"
                        />

                        <FormHelperText id="pricing-rsp-helper-text">
                          {values.rsp != "0" && values.rsp !== "0.00"
                            ? ((values.rsp * 100) / intragroup).toFixed(2) +
                              " %"
                            : ""}
                        </FormHelperText>
                      </td>

                      <td>
                        <TextField
                          label="RSP 2"
                          id="rsp2"
                          variant="outlined"
                          // value={values.rsp2}
                          inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                €
                              </InputAdornment>
                            ),
                          }}
                          value={
                            (values.rsp2 === "0" || values.rsp2 === "0.00") &&
                            collection
                              ? suggested.rsp2
                              : values.rsp2
                          }
                          onChange={(e) => {
                            setValues((oldValues) => ({
                              ...oldValues,
                              ["rsp2"]: e.target.value.replace(",", "."),
                            }));
                          }}
                          // onFocusOut={set("rsp2")}
                          aria-describedby="pricing-rsp-helper-text"
                        />

                        <FormHelperText id="pricing-rsp-helper-text">
                          {values.rsp2 &&
                          values.rsp2 != "0" &&
                          values.rsp2 !== "0.00"
                            ? ((values.rsp2 * 100) / intragroup).toFixed(2) +
                              " %"
                            : ""}
                        </FormHelperText>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Grid>
          {user.isAdmin && (
            <Grid item xs={12} mt={2}>
              <Button
                type="submit"
                variant="contained"
                component="span"
                startIcon={<SaveIcon />}
                sx={{marginLeft: 2}}
                onClick={updatePrices}
              >
                Confirm and Save Prices
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
}