import React, {useState, useEffect, useContext} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";

import InputAdornment from '@mui/material/InputAdornment';
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from '@mui/material/FormHelperText';

import FileListDisplay from "./FileListDisplay/FileListDisplay";

import Grid from "@mui/material/Grid";
import httpService from "../services/httpService";

import {exporterContext} from "../context/Context.js";

export default function ProductForm({component = false, initProduct = false , user }) {
  const [category, setCategory] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [color, setColor] = useState([]);
  const [brand, setBrand] = useState([]);
  const [newVal, setNewVal] = useState();
  const [savebuttonDisplay, setSavebuttonDisplay] = useState("");

  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    body: "",
    method: "",
  });

  //GET CONTEXT VARS
  const {
    collection,
    rows,
    setRows,
    isBOM,
    setIsBOM,
    setNewComponentOpen,
    productId,
    setProductId,
    values,
    setValues,
    setCollection,
    set,
    initRowsData,
    isUpdate,
    notification,
    setImages,
    setPdfs,
    pdfs
  } = useContext(exporterContext);

  const initValues = {
    name: "",
    brand: "",
    category: "",
    sku: "",
    barcode: "",
    supplier: "",
    supplierCode: "",
    color: "",
    volume: "",
    weight: "",
    isFollow: false,
    isBOM: false,
    isComponent: false,
    isSellable: false,
    attachedFile: true,
    isOutOfCollection: false,
    quantity: 0,
    purchasePrice: "0",
    height: "",
    width: "",
    intragroup: "0",
    b2b_a: "0",
    b2b_b: "0",
    b2b_c: "0",    
    depth: "",
    purchasePriceHistory: [],
    intragroupHistory: [],
    image: ""
  };



  // Get all dropdown data
  const fillDropDowns = async () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const categoryUrl = `${baseUrl}/category/get`;
    const colorUrl = `${baseUrl}/color/get`;
    const brandUrl = `${baseUrl}/brand/get`;
    const supplierUrl = `${baseUrl}/supplier/get`;

    if( Boolean(component) !== true) {
      // Get Init values for all dropdowns
      try {
        const categoryList = await httpService.get(categoryUrl);
        setCategory(categoryList.data);

        const colorList = await httpService.get(colorUrl);
        setColor(colorList.data);

        const brandList = await httpService.get(brandUrl);
        setBrand(brandList.data);

        const supplierList = await httpService.get(supplierUrl);
        setSupplier(supplierList.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

   // Save button is displayed just for child products
   const saveButton = () => {
    if(  user.isAdmin ) {
     if ( !isUpdate  ) {       
      return (
        <Button type="submit" variant="contained" style={{margin: "1rem auto"}}>          
             Save product general info
        </Button> 
        )
      } else {        
      // } else if ( Boolean(values.isComponent) === true) {        
        return (
          <Button type="submit" variant="contained" style={{margin: "1rem auto"}}>
            Update component information
          </Button> 
        )
      }  
    }
   };

  // On load
  useEffect( () => {

    const startRequest = async() => {
      await fillDropDowns();
      if (initProduct) {
        const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
        const productData = `${baseUrl}/product/get/${initProduct}`;
        let currrentProduct = await httpService.get(productData).catch((e) => {
          console.error(e);
        });
        
        if (currrentProduct.data.length > 0) {
          // flat purchase prices and set it as attirube

          let tmpPrice = currrentProduct.data[0].purchasePriceHistory.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          let lastPurchasePrice = currrentProduct.data[0].isBOM
            ? currrentProduct.data[0].purchasePriceHistory.length > 0
              ? tmpPrice[0].price
              : ""
            : currrentProduct.data[0].purchasePrice;

          let lastPurchasePriceDate = currrentProduct.data[0].purchasePriceHistory.length > 0 ? tmpPrice[0].date : "";

          let tmpPriceIntrgroup = currrentProduct.data[0].intragroupHistory.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });

          // TODO: Need to parse just date ? .split('T')[0]

          let lastIntragroupPriceDate = tmpPriceIntrgroup.length > 0 ? tmpPriceIntrgroup[0].date : "";

          currrentProduct.data[0].lastPurchasePrice = lastPurchasePrice;
          currrentProduct.data[0].lastPurchasePriceDate = lastPurchasePriceDate;
          currrentProduct.data[0].lastIntragroupPriceDate = lastIntragroupPriceDate;

          // SEPARATE PDFS AND IMAGE FILES          
          const imageList = currrentProduct.data[0].files.filter((file)=> (file.format.indexOf('image') !== -1))
          const pdfList = currrentProduct.data[0].files.filter((file)=> (file.format === 'application/pdf'))
          
          setImages(imageList)
          setPdfs(pdfList)
          setValues(currrentProduct.data[0]);
          console.log('currentProductdata', currrentProduct.data );
          console.log('initProduct', initProduct );

          if (currrentProduct.data[0].collection_id) {
            setCollection(currrentProduct.data[0].collection_id);
          }
        } else {
          setValues(initValues);
        }

        // TODO: Set collection on init for parent product
      }

      if (values.isBOM) {
        setIsBOM(true);
      }
    }
    startRequest();
    
  }, [initProduct]);


useEffect(() => {
  if (values.isBOM) {
    setIsBOM(true);
    setValues((oldValues) => ({...oldValues, ['isBOM']: true}));
  }
},[]);


  useEffect(() => {    
    if (values.isBOM) {
      setIsBOM(true);
    }    
    setSavebuttonDisplay(saveButton());
  }, [values, isBOM]);

  const handleDropdownChange = (event) => {
    if (event.target.value == "0") {
      handleClickOpen(event.target.name);
      return;
    }
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };



  const handleBOMChange = (event) => {
    // Save product
    setIsBOM(event.target.checked);
    setValues((oldValues) => ({...oldValues, ['isBOM']: event.target.checked}));
  };


  const handleSellableChange = (event) => {
    // Save product    
    setValues((oldValues) => ({...oldValues, ['isSellable']: event.target.checked}));
  };
  const handleOutOfCollectionChange = (event) => {
    // Save product    
    setValues((oldValues) => ({...oldValues, ['isOutOfCollection']: event.target.checked}));
  };

 
  // is it a component ?
  // TODO : not getting latest purchase price from history

  let purchaseComponent = (isBOM == false) ? (
    <Grid item xs={3}>
      <TextField
        id="purchasePrice"
        label="Purchase Price"
        variant="outlined"        
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}   
        value={ values.purchasePrice ? values.purchasePrice : values.purchasePrice || '' }
        onChange={set("purchasePrice")}      
        required
      />
      <FormHelperText id="purchasePrice-helper-text">
          { values.lastPurchasePriceDate ? values.lastPurchasePriceDate : ""}
      </FormHelperText>
    </Grid>
  ) : (
    ""
  );

  // Form submit handler
  const formSubmitter = async (event, b) => {
    event.preventDefault();
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

    let url = `${baseUrl}/product/create`;
    let isCreate = true;
    let params = {};
    
    if (productId != "" ) {    
      isCreate = false;
    }
    
    if (isCreate) {      
      params = values;      
      params.collection_id = collection._id;
      params.isBOM = isBOM
      if(values.color == "")
        delete values.color;
    } else if ( isUpdate ) {      
      params = values;      
      params.collection_id = collection._id;
      params.isBOM = isBOM      
      url = `${baseUrl}/product/update/${productId}`;
    }    

    // Make request to creation method
    httpService
      .post(url, params)
      .then(async function (response) {        
        // handle success        
        if (isCreate && component == false && Boolean(isBOM) === false ) {
          // setValues(initValues);
          window.history.replaceState({ id: 'WOOG', source: 'web' }, 'WOOG', `/products/${response.data._id}`);
          notification("Product created successfully 👍🏽");          
        } else if ( isCreate && Boolean(isBOM) === true ) {          
          window.history.replaceState({ id: 'WOOG', source: 'web' }, 'WOOG', `/products/${response.data._id}`);
          setProductId(response.data._id);
          notification("Product updated successfully 👍🏽");
          setIsBOM(false);
          setIsBOM(true);    
                
        } else if ( isUpdate  ) {                
          notification("Product updated successfully 👍🏽");          
          window.location.reload();

        }
        
      })
      .catch(function (error) {
        // handle error
        notification("Something went wrong 👎🏽", 'error');
        console.log("error during fetch", error);
      })
      .then(function () {
        // always executed
        setNewComponentOpen(false);
      });
    return false;
  };

 

  // Handle category dropdown
  const dropdownFiller = (arrayHolder = []) => {
    if (arrayHolder) {
      return arrayHolder.length > 0
        ? arrayHolder.map((cat) => (
            <MenuItem value={cat._id} key={cat._id}>
              {cat.name} { cat.odooID  ? `(${cat.odooID})` : "" }
            </MenuItem>
          ))
        : "";
    }
  };

  // Handle Add popup
  const handleClickOpen = (target) => {
    setDialogData({
      title: `Add a new ${target}`,
      body: `Please enter the name of the new ${target}`,
      method: target,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewSave = (element) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
    const url = `${baseUrl}/${element.target.getAttribute("method")}/create`;
    const values = {
      name: newVal,
    };
    // Make request to creation method
    httpService
      .post(url, values)
      .then((e) => {
        fillDropDowns();
        handleClose();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const skuHolder =  (
    <TextField
      id="sku"
      label="SKU"
      variant="outlined"
      fullWidth
      value={values.sku}
      onChange={set("sku")}
      required
    />
  );

  return (
    <div style={{margin: "2rem auto"}}>
      <form action="/" className="productForm" onSubmit={formSubmitter}>
        <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 3}}>
          <Grid item xs={6}>
            {skuHolder}
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Brand</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="brand"
                name="brand"
                label="brand"
                value={values.brand}
                // onChange={set("brand")}
                onChange={handleDropdownChange}
              >
                {dropdownFiller(brand)}

                <MenuItem value={0} name="brand">
                  {" "}
                  + Add Brand
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{minWidth: 120}}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="category"
                  label="Category"
                  value={values.category}
                  name="category"
                  // onChange={set("category")}
                  onChange={handleDropdownChange}
                >
                  {dropdownFiller(category)}

                  <MenuItem value={0} name="category">
                    {" "}
                    + Add Category
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField id="name" label="Product Name" variant="outlined" value={values.name} onChange={set("name")} fullWidth required />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField id="barcode" label="Barcode" variant="outlined" fullWidth value={values.barcode} onChange={set("barcode")} />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{minWidth: 120}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Supplier*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="supplier"
                  name="supplier"
                  label="Supplier*"
                  value={values.supplier}
                  onChange={handleDropdownChange}
                  required
                  // onChange={set("supplier")}
                >
                  {dropdownFiller(supplier)}

                  <MenuItem value={0} name="supplier">
                    {" "}
                    + Add Supplier
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="supplierCode"
              label="Supplier code"
              variant="outlined"
              required
              fullWidth
              value={values.supplierCode}
              onChange={set("supplierCode")}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <TextField
              id="volume"
              label="Volumen"
              variant="outlined"
              fullWidth
              // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              value={values.volume}
              onChange={set("volume")}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              id="weight"
              label="Weight"
              variant="outlined"
              fullWidth
              // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              value={values.weight}
              onChange={set("weight")}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              id="height"
              label="Height"
              variant="outlined"
              fullWidth
              // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              value={values.height}
              onChange={set("height")}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              id="width"
              label="Width/Broad"
              variant="outlined"
              fullWidth
              // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              value={values.width}
              onChange={set("width")}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              id="depth"
              label="Depth"
              type="number" //ad
              variant="outlined"
              fullWidth
              // inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
              value={values.depth}
              onChange={set("depth")}
            />
          </Grid>

          <Grid container item md={12} >
            <Grid md={6}>

              
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Color</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="color"
                    name="color"
                    label="Color"
                    value={values.color}
                    // onChange={set("brand")}
                    onChange={handleDropdownChange}
                  >
                    {dropdownFiller(color)}

                    <MenuItem value={0} name="color">
                      {" "}
                      + Add Color
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>



              <Grid container item sm={12}>
                <FormGroup>
                  <FormControlLabel
                    onChange={handleBOMChange}
                    value={values.isBOM}
                    control={<Switch checked={isBOM} />}
                    label="Is it BOM?"
                    id="isBOM"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={values.isSellable} />}
                    value={values.isSellable}
                    onChange={handleSellableChange}
                    label="Not Available online?"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={values.isOutOfCollection} />}
                    value={values.isOutOfCollection}
                    onChange={handleOutOfCollectionChange}
                    label="End of lifecycle?"
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid md={6} paddingLeft={5}>
              <FileListDisplay fileData={pdfs} />
            </Grid>
          </Grid>

          {purchaseComponent}
        </Grid>
        {savebuttonDisplay}
      </form>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogData.body}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="newField"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setNewVal(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleNewSave} method={dialogData.method}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
