import {useEffect, useState} from "react";
import {initRowsData} from "../../../../api/priceList";

export const useInitialData = ({httpService}) => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  
  
  useEffect(() => {    
    async function getInitialRows() {      
      const response = await initRowsData(httpService);
      setRows(response)
      setLoading(false)
    }    
      getInitialRows();
  }, [httpService]);

  return {rows, loading};
};
