let formData = [];
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

formData["collection-creation"] = {
  dialogText: {
    title: "New Collection",
    body: "Enter the collection data ",
  },
  submit: {
    method: "POST",
    url: `${baseUrl}/collection/create`,
  },
  edit: {
    method: "POST",
    url: `${baseUrl}/collection/update`,
  },
  fields: [
    {
      label: "Name",
      name: "name",
      value: "",
    },
    {
      label: "B2B_A",
      name: "pricing.b2b_a",
      value: "",
    },
    {
      label: "B2B_B",
      name: "pricing.b2b_b",
      value: "",
    },
    {
      label: "B2B_C",
      name: "pricing.b2b_c",
      value: "",
    },
    {
      label: "RSP 1",
      name: "pricing.rsp",
      value: "",
    },
    {
      label: "RSP 2",
      name: "pricing.rsp2",
      value: "",
    },
  ],
};

formData["collection-link-extra"] = {
  dialogText: {
    title: "Add collection field",
    body: "Enter extra field details ",
  },
  edit: {
    method: "POST",
    url: `${baseUrl}/collection/editExtraFields`,
  },
  submit: {
    method: "POST",
    url: `${baseUrl}/collection/addExtraFields`,
  },
  fields: [
    {
      label: "Name",
      name: "name",
      value: "",
    },
    {
      label: "Value",
      name: "value",
      value: "",
    },
  ],
};

formData["attachment"] = {
  dialogText: {
    title: "Add files to collection",
    body: "Add images or pdf files",
  },
  edit: {
    method: "POST",
    url: `${baseUrl}/collection/editExtraFields`,
  },
  submit: {
    method: "POST",
    url: `${baseUrl}/collection/attachFile`,
  },
  fields: [],
};


export default formData;