let headerData = {}
headerData.facebook = [{
  "label": 'ID',
  "field": "sku"
}, {
  "label": 'title',
  "field": "name"
}, {
  "label": 'description',
  "field": "short_description"
}, {
  "label": 'availability',
  "default": "in stock"
}, {
  "label": 'condition',
  "default": "new"
}, {
  "label": 'Price',
  "field": "price.default.value"
}, {
  "label": 'link',
  "field": "url_key"
}, {
  "label": 'image_link',
  "field": "images"
}, {
  "label": 'brand',
  "field": "MARQUE"
}, {
  "label": 'collection',
  "field": "COLLECTIONS"
}];

headerData.google = [
  {
    label: "ID",
    field: "sku",
  },
  {
    label: "Titel",
    field: "name",
  },
  {
    label: "Beschreibung",
    field: "short_description",
  },
  {
    label: "Produkt Url",
    field: "url_key",
  },
  {
    label: "Zustand ",
    default: "new",
  },
  {
    label: "Preis",
    field: "price.default.original_value",
  },
  {
    label: "sale_price",
    field: "price.default.value",
  },
  {
    label: "Verfügbarkeit",
    default: "in_stock",
  },
  {
    label: "Bild Link",
    field: "images",
  },
  {
    label: "gtin",
    field: "EAN",
  },
  {
    label: "npm",
    default: "",
  },
  {
    label: "Marke",
    field: "MARQUE",
  },
  {
    label: "Número pieza fabricante (MPN)",
    field: "supplier_reference",
  }
]



module.exports = headerData;
